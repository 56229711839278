import {
  isTransactionInitiatedByLoggedAccount,
  getLoggedAccountTransactionRoleId,
} from "./account";

/**
 * Check if the initiator needs to accept the agreement.
 * @param {*} transaction 
 * @returns {boolean}
 */
export function requiresInitiatorToAcceptAgreement(transaction) {
  const initiatedByLoggedAccount = isTransactionInitiatedByLoggedAccount(transaction);
  return initiatedByLoggedAccount && transaction.agreement_accepted_by_initiator === null;
}

/**
 * Check if the counterparty needs to accept the agreement.
 * @param {*} transaction 
 * @returns {boolean}
 */
export function requiresCounterpartyToAcceptAgreement(transaction) {
  const initiatedByLoggedAccount = isTransactionInitiatedByLoggedAccount(transaction);
  return !initiatedByLoggedAccount && transaction.agreement_accepted_by_counterparty === null;
}

/**
 * Check if confirmation of items sent is required.
 * @param {*} transaction 
 * @returns {boolean}
 */
export function requiresConfirmationOfItemsSent(transaction) {
  const roleId = getLoggedAccountTransactionRoleId(transaction);
  return roleId === 1 && !transaction.items_sent &&
    transaction.escrow_received === true &&
    transaction.agreement_accepted_by_initiator &&
    transaction.agreement_accepted_by_counterparty;
}

/**
 * Check if confirmation of refused items sent is required.
 * @param {*} transaction 
 * @returns {boolean}
 */
export function requiresConfirmationOfRefusedItemsSent(transaction) {
  const roleId = getLoggedAccountTransactionRoleId(transaction);
  return roleId === 2 && transaction.items_sent === true &&
    transaction.inspection_completed_successfully === false &&
    transaction.inspection_items_refused_sent === null &&
    transaction.conflict_resolved === true &&
    transaction.transaction_last_conflict_resolution !== null &&
    transaction.transaction_last_conflict_resolution.transaction_last_conflict_resolution.id === 3 &&
    transaction.agreement_accepted_by_initiator === true &&
    transaction.agreement_accepted_by_counterparty === true;
}

/**
 * Check if confirmation of items received is required.
 * @param {*} transaction 
 * @returns {boolean}
 */
export function requiresConfirmationOfItemsReceived(transaction) {
  const roleId = getLoggedAccountTransactionRoleId(transaction);
  return roleId === 2 && transaction.items_sent &&
    transaction.items_received === null &&
    transaction.agreement_accepted_by_initiator &&
    transaction.agreement_accepted_by_counterparty;
}

/**
 * Check if confirmation of refused items received is required.
 * @param {*} transaction 
 * @returns {boolean}
 */
export function requiresConfirmationOfRefusedItemsReceived(transaction) {
  const roleId = getLoggedAccountTransactionRoleId(transaction);
  return roleId === 1 && transaction.items_sent &&
    transaction.items_received === true &&
    transaction.inspection_completed_successfully === false &&
    transaction.inspection_items_refused_sent === true &&
    transaction.inspection_items_refused_received === null &&
    transaction.agreement_accepted_by_initiator &&
    transaction.agreement_accepted_by_counterparty;
}

/**
 * Check if confirmation of items inspection is required.
 * @param {*} transaction 
 * @returns {boolean}
 */
export function requiresConfirmationOfItemsInspection(transaction) {
  const roleId = getLoggedAccountTransactionRoleId(transaction);
  return roleId === 2 && transaction.items_sent === true &&
    transaction.items_received === true &&
    transaction.inspection_completed_successfully === null &&
    transaction.agreement_accepted_by_initiator === true &&
    transaction.agreement_accepted_by_counterparty === true;
}

/**
 * Check if confirmation of returned items inspection is required.
 * @param {*} transaction 
 * @returns {boolean}
 */
export function requiresConfirmationOfReturnedItemsInspection(transaction) {
  const roleId = getLoggedAccountTransactionRoleId(transaction);
  return roleId === 1 && transaction.items_sent &&
    transaction.items_received === true &&
    transaction.inspection_completed_successfully === false &&
    transaction.inspection_items_refused_sent === true &&
    transaction.inspection_items_refused_received === true &&
    transaction.inspection_items_refused_received_inspection_completed_success === null &&
    transaction.agreement_accepted_by_initiator &&
    transaction.agreement_accepted_by_counterparty;
}

/**
 * Check if conflict resolution acceptance by initiator is required.
 * @param {*} transaction 
 * @returns {boolean}
 */
export function requiresConflictResolutionAcceptanceByInitiator(transaction) {
  return isTransactionInitiatedByLoggedAccount(transaction) === true &&
    transaction.conflict === true &&
    (transaction.conflict_resolution_accepted_by_initiator === null || transaction.conflict_resolution_accepted_by_initiator === false);
}

/**
 * Check if conflict resolution acceptance by counterparty is required.
 * @param {*} transaction 
 * @returns {boolean}
 */
export function requiresConflictResolutionAcceptanceByCounterparty(transaction) {
  return isTransactionInitiatedByLoggedAccount(transaction) === false &&
    transaction.conflict === true &&
    (transaction.conflict_resolution_accepted_by_counterparty === null || transaction.conflict_resolution_accepted_by_counterparty === false);
}
