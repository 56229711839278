const initialState = false;

const ACTIONS = {
    TRANSACTION_DATA_UPDATED: 'transactionDataUpdated'
};

export default function filtersReducer(state = initialState, action) {
    if (action.type === ACTIONS.TRANSACTION_DATA_UPDATED) {
        return action.payload.transactionDataChanged;
    } else {
        return state;
    }
}