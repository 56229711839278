import React, { useState, useEffect } from "react";
import LoginPage from "../Login/LoginPage";
import { Table, Card, Space, Row, Col, Tag, message } from "antd";
import { formatPolishDate } from "../../helpers/date";
import { formatPolishCurrency } from "../../helpers/currency";
import { isLogged, getAuthToken, destroyAuthToken } from "../../helpers/account";
import { api } from "../../config/api";
import { DollarOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import NewTransactionForm from "../../components/Transaction/Forms/NewTransactionForm";

const columns = [
  {
    title: "Tytuł transakcji",
    dataIndex: "title",
    key: "title",
    fixed: "left",
    sorter: true,
    render: (title) => (
      <>
        <u style={{ cursor: "pointer" }}>{title}</u>
      </>
    ),
  },
  {
    title: "Utworzona",
    dataIndex: "created_at",
    key: "created",
    render: (created_at) => formatPolishDate(created_at),
    className: "hide-on-mobile",
  },
  {
    title: "Wartość",
    dataIndex: "escrow_amount",
    key: "value",
    render: (escrow_amount) => formatPolishCurrency(escrow_amount),
  },
  {
    title: "Status",
    dataIndex: "finished",
    key: "status",
    render: (finished) =>
      finished ? (
        <Tag color="magenta">Zakończona</Tag>
      ) : (
        <Tag color="green">Trwa</Tag>
      ),
  },
];

function TransactionsPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const transactionDataChanged = useSelector(
    (state) => state.transactionDataChanged
  );

  useEffect(() => {
    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/transactions`; // Update with your API endpoint

    if (sessionStorage.getItem("authToken")) {
      // Fetch data from the API
      fetch(apiUrl, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`, // Replace "Bearer" if your API expects a different prefix
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setData(data); // Update the state with the fetched data
          setLoading(false); // Set loading to false
        })
        .catch((error) => {
          setTimeout(() => {
            message.error("Dla bezpieczeństwa zostałeś wylogowany.");
            destroyAuthToken();
            window.location.href = "/transactions";
          }, 2000);
          setLoading(false); // Set loading to false even on error
        });
    }
  }, [transactionDataChanged]);

  if (isLogged()) {
    return (
      <>
        <Row
          style={{
            minHeight: "100vh",
          }}
        >
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Space direction="vertical">
              <Card
                title={
                  <>
                    <DollarOutlined /> Transakcje
                  </>
                }
              >
                <Space size={30} direction="vertical">
                  <NewTransactionForm />
                  <Table
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          window.location.href = `/transaction/${record.uuid}`;
                        }, // click row
                        onDoubleClick: (event) => {}, // double click row
                        onContextMenu: (event) => {}, // right button click row
                        onMouseEnter: (event) => {}, // mouse enter row
                        onMouseLeave: (event) => {}, // mouse leave row
                      };
                    }}
                    className="shadow"
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    locale={{
                      emptyText:
                        "Jeszcze nie brałeś udziału w żadnej transakcji",
                    }}
                  />
                </Space>
              </Card>
            </Space>
          </Col>
        </Row>
      </>
    );
  } else {
    return <LoginPage></LoginPage>;
  }
}

export default TransactionsPage;
