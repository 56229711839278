const initialState = false;

const ACTIONS = {
    ACCOUNT_DATA_CHANGED: 'accountDataChanged'
};

export default function filtersReducer(state = initialState, action) {
    if (action.type === ACTIONS.ACCOUNT_DATA_CHANGED) {
        return action.payload.accountDataChanged;
    } else {
        return state;
    }
}