import React, { useState } from "react";
import {
  Alert,
  Button,
  Space,
  Drawer,
  Card,
  Input,
  message as messageApi,
} from "antd";
import { api } from "../../../config/api";
import { useDispatch } from "react-redux";
import { getAuthToken } from "../../../helpers/account";
import { requiresConfirmationOfItemsSent } from "../../../helpers/actions";

const ConfirmItemsSentAction = (props) => {
  const [open, setOpen] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [trackingNumberConfirmation, setTrackingNumberConfirmation] =
    useState("");
  const [loading, setLoading] = useState(false); // Added loading state
  const dispatch = useDispatch();
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleConfirmation = () => {
    setLoading(true);

    // Check if tracking numbers match
    if (trackingNumber !== trackingNumberConfirmation) {
      messageApi.open({
        type: "error",
        content: "Numer przewoźnika i potwierdzenie muszą być takie same.",
      });
      setLoading(false);
      return;
    }

    if (trackingNumber === "") {
      messageApi.open({
        type: "error",
        content: "Numer przewoźnika jest wymagany.",
      });
      setLoading(false);
      return;
    }    

    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/transactions/${props.transaction.uuid}`;
    const jsonData = {
      items_sent: true,
      shipping_tracking_code: trackingNumber,
    };

    // Fetch data from the API
    fetch(apiUrl, {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      }),
      body: JSON.stringify(jsonData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        messageApi.open({
          type: "success",
          content: "Potwierdzenie zostało zapisane.",
        });
        setTimeout(() => {
          dispatch({
            type: "transactionDataUpdated",
            payload: {
              transactionDataChanged: new Date().getSeconds(),
            },
          });
          setLoading(false);
        }, 2000); // Delay for 2 seconds
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Wystąpiły błędy podczas zapisu stanu transakcji.",
        });
        setLoading(false);
      });
  };

  let canBeRendered = false;

  if (requiresConfirmationOfItemsSent(props.transaction)) {
    canBeRendered = true;
  }

  if (canBeRendered === true) {
    return (
      <>
        <Drawer
          title="Potwierdzenie wysyłki przedmiotu"
          placement="right"
          onClose={onClose}
          open={open}
        >
          <Card
            title="Numer śledzenia przesyłki"
            style={{
              width: 300,
            }}
          >
            <Space size={30} direction="vertical">

              <Alert type="info" message="Dla produktów cyfrowych dostarczanych przez Internet (na przykład za pośrednictwem e-maila lub FTP), proszę podać numer 0000.">

              </Alert>

              <div>
                <Input
                  required={true}
                  placeholder="Numer przesyłki"
                  autoComplete="off"
                  value={trackingNumber}
                  onChange={(e) => setTrackingNumber(e.target.value)}
                />
              </div>
              <div>
                <Input
                  required={true}
                  placeholder="Potwierdź numer przesyłki"
                  autoComplete="off"
                  value={trackingNumberConfirmation}
                  onChange={(e) =>
                    setTrackingNumberConfirmation(e.target.value)
                  }
                />
              </div>
              <Button type="primary" onClick={handleConfirmation} loading={loading}>
                Zapisz
              </Button>
            </Space>
          </Card>
        </Drawer>
        <Alert
          type="warning"
          message={<>Dostarcz przedmiot umowy</>}
          description="Zgodnie umową, dostarcz przedmiot do ustalonej daty. Potwierdź wysyłkę podając numer przesyłki."
          action={
            <Space direction="vertical">
              <Button type="primary" onClick={showDrawer}>
                Potwierdzam wysyłkę
              </Button>
            </Space>
          }
        />
      </>
    );
  }
};

export default ConfirmItemsSentAction;
