import React, { useState, useEffect } from "react";
import { Steps } from "antd";

const ProgressBarTransactionMode1 = (props) => {
  const [currentStage, setCurrentStage] = useState(0);

  useEffect(() => {
    if (props.transaction) {
      if (
        props.transaction.agreement_accepted_by_counterparty === true &&
        props.transaction.agreement_accepted_by_initiator === true
      ) {
        setCurrentStage(1);

        if (props.transaction.escrow_received) {
          setCurrentStage(2);

          if (props.transaction.items_sent) {
            setCurrentStage(3);

            if (props.transaction.items_received) {
              setCurrentStage(4);

              if (props.transaction.inspection_completed_successfully) {
                setCurrentStage(5);

                if (props.transaction.finished) {
                  setCurrentStage(6);
                }
              }
            }
          }
        }
      }
    }
  }, [props.transaction]);

  const steps = [
    {
      title: "Umowa",
      description: "Uzgodnienie i akceptacja warunków",
    },
    {
      title: "Depozyt",
      description: "Zabezpieczenie środków na transakcję",
    },
    {
      title: "Dostawa",
      description: "Dostarczenie towaru lub usługi",
    },
    {
      title: "Odbiór",
      description: "Potwierdzenie odbioru",
    },
    {
      title: "Inspekcja",
      description: "Sprawdzenie zgodności z umową",
    },
    {
      title: "Zakończenie transakcji",
      description: "Wypłata środków",
    },
  ];

  if (
    (props.transaction !== null &&
      (props.transaction.transaction_mode_id !== 1))
  ) {
    return <></>;
  }

  if (props.transaction !== null) {
    return <Steps size="small" current={currentStage} items={steps} />;
  }
};

export default ProgressBarTransactionMode1;
