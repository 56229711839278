import React, { useState, useEffect, useRef } from "react";
import { Space, Button, Typography, Input, message, Spin, Card, Alert } from "antd";
import { isLogged, getAuthToken, destroyAuthToken } from "../../../helpers/account";
import { api } from "../../../config/api";
import { formatPolishDate } from "../../../helpers/date";
import {
    FieldTimeOutlined
} from "@ant-design/icons";

const ChatWindow = (props) => {
    const [loading, setLoading] = useState(false);
    const [messageSent, setMessageSent] = useState(false);
    const [messages, setMessages] = useState([]);
    const [messageInput, setMessageInput] = useState('');
    const [isScrolling, setIsScrolling] = useState(false);
    const { TextArea } = Input;
    const { Text, Link } = Typography;

    // Ref for the placeholder div
    const messagesEndRef = useRef(null);

    /**
     * Scrolls to the bottom of the message container
     */
    const scrollToBottom = () => {
        if (!isScrolling && messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    /**
     * Handles sending a message
     * @returns {void}
     */
    const handleSendMessage = async () => {

        if (!messageInput) {
            return;
        }

        setLoading(true);
        const apiUrl = `${api.host}/transactions/chats`;

        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: getAuthToken(),
                },
                body: JSON.stringify({
                    uuid: props.transactionUuid,
                    message: messageInput
                })
            });

            if (response.ok) {
                const responseData = await response.json();
                message.success("Wiadomość wysłana");
                setMessageInput('');
                setMessageSent(true);
                scrollToBottom();
            } else {
                const errorData = await response.json();
                message.error(errorData.error);
            }
        } catch (error) {
            console.error("Error sending message:", error);
            message.error("An error occurred while sending the message.");
        } finally {
            setLoading(false);
        }
    };

    /**
     * Fetches messages from the server
     * @returns {void}
     */
    const fetchMessages = async () => {
        if (props.transactionUuid || messageSent) {
            const apiUrl = `${api.host}/transactions/chats/${props.transactionUuid}`;

            try {
                const response = await fetch(apiUrl, {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        Authorization: `${getAuthToken()}`,
                    }),
                });

                if (response.ok) {
                    const data = await response.json();
                    setMessages(data);
                    setLoading(false);
                } else {
                    throw new Error('Failed to fetch messages');
                }
            } catch (error) {
                setTimeout(() => {
                    message.error("Dla bezpieczeństwa zostałeś wylogowany.");
                    destroyAuthToken();
                    window.location.href = "/transactions";
                }, 2000);
                setLoading(false);
            }
        }
    };

    // Fetch messages when component mounts and set interval for periodic updates
    useEffect(() => {
        fetchMessages();
        const intervalId = setInterval(fetchMessages, 5000);
        return () => clearInterval(intervalId);
    }, [props.transactionUuid, messageSent]);

    /**
     * Handles scrolling event
     * @returns {void}
     */
    const handleWheel = () => {
        setIsScrolling(true);
        // Clear the scrolling state after a delay
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
            setIsScrolling(false);
        }, 1500);
    };

    let scrollTimeout;

    return (
        <>
            <Spin spinning={loading}>
                <Space direction="vertical" style={{ width: "100%" }}>
                    {/* Top div with gradient background */}
                    <div
                        style={{
                            top: "0",
                            height: "50px",
                            position: "absolute",
                            background: "linear-gradient('#aaa', 'transparent')",
                            opacity: "0.9",
                            width: "100%",
                            zIndex: "1000",
                            borderRadius: "5px"
                        }}
                    >&nbsp;</div>

                    {/* Container div for messages */}
                    <div
                        id="chat"
                        style={{ height: messages.length == 0 ? "100px" : "290px", overflowY: "scroll", paddingBottom: "50px", width: "100%" }}
                        onWheel={handleWheel}
                    >
                        <style>
                            {`
            #chat::-webkit-scrollbar {
                display: none; /* Hide the scrollbar */
            }
        `}
                        </style>
                        <Space direction="vertical" size={10}>
                            {/* Map through messages and render each as a Card */}
                            {messages.length == 0 ? <Alert showIcon message="W czasie trwania transakcji nie zostały wymienione jeszcze żadne wiadomości." type="info" /> : ''}
                            {messages.map((msg) => (
                                <Card key={msg.uuid} size="small">
                                    <Space size={1} direction="vertical">
                                        <Text keyboard><nobr>{msg.account.fullname}</nobr></Text>
                                        <Space size={5} style={{ fontSize: "75%" }}>
                                            <FieldTimeOutlined /> {formatPolishDate(msg.created_at)}
                                        </Space>
                                    </Space>
                                    <p>{msg.message}</p>
                                </Card>
                            ))}
                            {/* Placeholder div for scrolling */}
                            <div ref={messagesEndRef} />
                        </Space>
                    </div>
                    <Space direction="vertical" style={{ width: "100%" }}>

                        {/* Textarea for message input */}
                        <TextArea
                            placeholder="Wpisz wiadomość..."
                            autoSize={{
                                minRows: 2,
                                maxRows: 6,
                            }}
                            value={messageInput}
                            onChange={(e) => {
                                setMessageInput(e.target.value);
                                if (messageSent) { setMessageSent(false) };
                            }}
                        />
                        {/* Button to send message */}
                        <Button type="primary" onClick={handleSendMessage}>Wyślij</Button>
                    </Space>
                </Space>
            </Spin>
        </>
    );
};

export default ChatWindow;
