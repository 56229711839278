const initialState = false;

const ACTIONS = {
    TRANSACTION_CREATED: 'transactionCreated'
};

export default function filtersReducer(state = initialState, action) {
    if (action.type === ACTIONS.TRANSACTION_CREATED) {
        return action.payload.transactionCreated;
    } else {
        return state;
    }
}