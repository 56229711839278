import React, { useState } from "react";
import { Alert, Button, Space, message } from "antd";
import { api } from "../../../config/api";
import { getAuthToken } from "../../../helpers/account";
import { useDispatch } from "react-redux";
import { requiresConfirmationOfItemsReceived } from "../../../helpers/actions";

const ConfirmItemsReceivedAction = (props) => {
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();

  let canBeRendered = false;

  if (requiresConfirmationOfItemsReceived(props.transaction)) {
    canBeRendered = true;
  }

  const handleAccept = () => {
    setLoading(true);

    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/transactions/${props.transaction.uuid}`;
    const jsonData = {
      items_received: true,
    };

    // Fetch data from the API
    fetch(apiUrl, {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      }),
      body: JSON.stringify(jsonData), // Convert JSON object to a string
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        messageApi.open({
          type: "success",
          content: "Informacja o otrzymaniu przedmiotu umowy została zapisana.",
        });
        setTimeout(() => {
          // Additional logic to be executed after a delay (2 seconds in this case)
          dispatch({
            type: "transactionDataUpdated",
            payload: {
              transactionDataChanged: new Date().getSeconds(),
            },
          });
          setLoading(false);
        }, 2000); // Delay for 2 seconds
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Wystąpiły błedy podczas zapisu umowy.",
        });
        setLoading(false); // Set loading to false even on error
      });
  };

  const handleReject = () => {
    setLoading(true);

    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/transactions/${props.transaction.uuid}`;
    const jsonData = {
        items_received: false,
    };

    // Fetch data from the API
    fetch(apiUrl, {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      }),
      body: JSON.stringify(jsonData), // Convert JSON object to a string
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        messageApi.open({
          type: "success",
          content: "Informacja o braku przedmiotu umowy została zapisana.",
        });
        setTimeout(() => {
          // Additional logic to be executed after a delay (2 seconds in this case)
          dispatch({
            type: "transactionDataUpdated",
            payload: {
              transactionDataChanged: new Date().getSeconds(),
            },
          });
          setLoading(false);
        }, 2000); // Delay for 2 seconds
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Wystąpiły błedy podczas zapisu umowy.",
        });
        setLoading(false); // Set loading to false even on error
      });
  };

  if (canBeRendered === true) {
    return (
      <>
        {contextHolder}
        <Alert
          message="Potwierdź odebranie przedmiotu umowy"
          description="Jeśli już otrzymałeś przedmiot z umowy, prosimy o potwierdzenie tego faktu. Jeśli upłynął ustalony termin dostarczenia usługi, a wciąż nie otrzymałeś zamówionego przedmiotu - wybierz [Brak]."
          type="warning"
          action={
            <Space direction="vertical">
              <Button
                type="primary"
                onClick={handleAccept}
                loading={loading}
              >
                Odebrałem
              </Button>
              <Button
                danger
                ghost
                onClick={handleReject}
                loading={loading}
              >
                Brak
              </Button>
            </Space>
          }
        />
      </>
    );
  }
};

export default ConfirmItemsReceivedAction;
