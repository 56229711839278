import {
  Space,
  Descriptions,
  Spin,
} from "antd";
import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { api } from "../../config/api";
import { formatPolishCurrency } from "../../helpers/currency";
import { Helmet } from "react-helmet-async";

const PricingPage = () => {
  const [categoryPricingData, setCategoryPricingData] = useState([]);
  const [escrowFeeSettingsData, setEscrowFeeSettingsData] = useState([]);
  const [loadingCategoryPricing, setLoadingCategoryPricing] = useState(true);
  const [loadingEscrowFeePricing, setLoadingEscrowFeePricing] = useState(true);

  const categoryPricingColumns = [
    {
      title: "Nazwa kategorii",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      sorter: true,
      render: (name) => <>{name}</>,
    },
    {
      title: "Cena Netto",
      dataIndex: "escrow_fee",
      key: "escrow_fee",
      render: (escrow_fee) => formatPolishCurrency(escrow_fee),
    },
  ];

  useEffect(() => {
    if (loadingCategoryPricing === true) {
      // Your API endpoint URL goes here
      const apiUrl = `${api.host}/categories`; // Update with your API endpoint

      // Fetch data from the API
      fetch(apiUrl, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setCategoryPricingData(data); // Update the state with the fetched data
          setLoadingCategoryPricing(false); // Set loading to false
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoadingCategoryPricing(false); // Set loading to false even on error
        });
    }
  }, [loadingCategoryPricing]);

  useEffect(() => {
    if (loadingEscrowFeePricing === true) {
      // Your API endpoint URL goes here
      const apiUrl = `${api.host}/escrow-fee-settings`; // Update with your API endpoint

      // Fetch data from the API
      fetch(apiUrl, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setEscrowFeeSettingsData(data[0]); // Update the state with the fetched data
          setLoadingEscrowFeePricing(false); // Set loading to false
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoadingEscrowFeePricing(false); // Set loading to false even on error
        });
    }
  }, [loadingEscrowFeePricing]);

  return (
    <>
      {" "}
      <Helmet>
        <title>Postpay - Cennik</title>
      </Helmet>
      <div className="hero-background" style={{ marginTop: "-120px" }}>
        <div className="hero-background-5" style={{ paddingTop: "100px" }}>
          Nie komplikujemy niepotrzebnie życia. Prosty cennik złożony z dwóch
          elementów.
        </div>
      </div>
      <div className="abouttxt">
        <h3>19,90 za transakcję + procent od wartości przedmiotu transakcji</h3>

        <p>
          Zapewniamy przejrzysty system opłat: stała opłata za kategorię, w
          której umieszczony jest przedmiot transakcji i procent od wartości
          przedmiotu umowy. Zapewniamy bezpieczeństwo i uczciwość, gwarantując
          spokojne transakcje.
        </p>

        <h3 style={{marginTop:"30px"}}>Prowizje i dodatkowe koszty</h3>

        <Space size={30} direction="vertical">
          <Spin spinning={loadingEscrowFeePricing}>
            <Descriptions
              layout="vertical"
              bordered
              items={[
                {
                  key: "asdasd2",
                  label: "Prowizja od wartości transakcji",
                  children: <>{escrowFeeSettingsData.percentage * 100}%</>,
                },
              ]}
            />
            <br />
            <Descriptions
              layout="vertical"
              bordered
              items={[
                {
                  key: "asdasd",
                  label:
                    "Przekazanie konfliktu do rozwiązania przez negocjatora",
                  children: (
                    <>
                      {formatPolishCurrency(
                        escrowFeeSettingsData.conflict_resolution_cost
                      )}
                    </>
                  ),
                },
              ]}
            />
          </Spin>
        </Space>
      </div>
      <div className="ceo">
        <p>
          {" "}
          W odpowiedzi na rosnący problem transakcji oszukańczych, stworzyliśmy
          usługę Postpay – parasol ochronny dla Twoich transakcji w Internecie.
        </p>
        <p>
          <strong>
            Minimalizujemy ryzyko chroniąc Twoje pieniądze przed oszustwami.
            Wybierz Postpay i ciesz się spokojem podczas każdej transakcji
            online.
          </strong>
        </p>
        <div className="photo">
          <img src="/img/svg/3.svg" />
        </div>
        <div className="sign">
          <img src="/img/svg/4.svg" />
        </div>
        <div className="postpay">Postpay CEO</div>
      </div>
      <div className="partners">
        <img src="/img/svg/14.svg" />
      </div>
    </>
  );
};

export default PricingPage;
