import React, { useState, useEffect } from "react";
import { Steps } from "antd";

const ProgressBarTransactionMode4 = (props) => {
  const [currentStage, setCurrentStage] = useState(0);

  useEffect(() => {
    if (props.transaction) {
      if (
        props.transaction.agreement_accepted_by_counterparty === true &&
        props.transaction.agreement_accepted_by_initiator === true
      ) {
        setCurrentStage(1);
      }

      if (props.transaction.finished) {
        setCurrentStage(6);
      }
    }
  }, [props.transaction]);

  const steps = [
    {
      title: "Umowa",
      description: "Uzgodnienie i akceptacja warunków",
      status: "error",
    },

    {
      title: "Zakończenie transakcji",
      description: "Brak akceptacji warunków umowy",
    },
  ];

  if (
    props.transaction !== null &&
    props.transaction.transaction_mode_id !== 4
  ) {
    return <></>;
  }

  if (props.transaction !== null) {
    return <Steps size="small" current={currentStage} items={steps} />;
  }
};

export default ProgressBarTransactionMode4;
