import React from "react";
import AcceptAgreementAction from "./AcceptAgreementAction";
import LoadEscrowAction from "./LoadEscrowAction";
import ConfirmItemsSentAction from "./ConfirmItemsSentAction";
import ConfirmItemsReceivedAction from "./ConfirmItemsReceivedAction";
import ConfirmInspectionStatusAction from "./ConfirmInspectionStatusAction";
import ResolveConflictAction from "./ResolveConflictAction";
import ConfirmRefusedItemsSentAction from "./ConfirmRefusedItemsSentAction";
import ConfirmRefusedItemsReceivedAction from "./ConfirmRefusedItemsReceivedAction";
import ConfirmInspectionRefusedItemsStatusAction from "./ConfirmInspectionRefusedItemsStatusAction";

const ActionBar = (props) => {
  return (
    <>
      <AcceptAgreementAction
        transaction={props.transaction}
      ></AcceptAgreementAction>
      <LoadEscrowAction transaction={props.transaction}></LoadEscrowAction>
      <ConfirmItemsSentAction
        transaction={props.transaction}
      ></ConfirmItemsSentAction>
      <ConfirmItemsReceivedAction
        transaction={props.transaction}
      ></ConfirmItemsReceivedAction>
      <ConfirmInspectionStatusAction
        transaction={props.transaction}
      ></ConfirmInspectionStatusAction>
      <ResolveConflictAction
        transaction={props.transaction}
      ></ResolveConflictAction>
      <ConfirmRefusedItemsSentAction
        transaction={props.transaction}
      ></ConfirmRefusedItemsSentAction>
      <ConfirmRefusedItemsReceivedAction
        transaction={props.transaction}
      ></ConfirmRefusedItemsReceivedAction>
      <ConfirmInspectionRefusedItemsStatusAction
        transaction={props.transaction}
      ></ConfirmInspectionRefusedItemsStatusAction>
    </>
  );
};

export default ActionBar;
