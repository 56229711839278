import React, { useState } from "react";
import { Alert, Button, Space, message, Drawer, Input } from "antd";
import { api } from "../../../config/api";
import { getAuthToken } from "../../../helpers/account";
import { useDispatch } from "react-redux";
import { requiresConfirmationOfItemsInspection } from "../../../helpers/actions";

const ConfirmInspectionStatusAction = (props) => {
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [inspectionNote, setInspectionNote] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  let canBeRendered = false;

  if (requiresConfirmationOfItemsInspection(props.transaction)) {
    canBeRendered = true;
  }

  const handleAccept = () => {
    setLoading(true);

    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/transactions/${props.transaction.uuid}`;
    const jsonData = {
      inspection_completed_successfully: true,
    };

    // Fetch data from the API
    fetch(apiUrl, {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      }),
      body: JSON.stringify(jsonData), // Convert JSON object to a string
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        messageApi.open({
          type: "success",
          content: "Informacja o otrzymaniu przedmiotu umowy została zapisana.",
        });
        setTimeout(() => {
          // Additional logic to be executed after a delay (2 seconds in this case)
          dispatch({
            type: "transactionDataUpdated",
            payload: {
              transactionDataChanged: new Date().getSeconds(),
            },
          });
          setLoading(false);
        }, 2000); // Delay for 2 seconds
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Wystąpiły błedy podczas zapisu umowy.",
        });
        setLoading(false); // Set loading to false even on error
      });
  };

  const handleRejectConfirmation = () => {
    if (inspectionNote == "") {
      messageApi.open({
        type: "error",
        content: "Krótki opis niezgodności przedmiotu z umową jest wymagany.",
      });
      return false;
    }

    setLoading(true);

    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/transactions/${props.transaction.uuid}`;
    const jsonData = {
      inspection_completed_successfully: false,
      inspection_note: inspectionNote,
    };

    // Fetch data from the API
    fetch(apiUrl, {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      }),
      body: JSON.stringify(jsonData), // Convert JSON object to a string
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        messageApi.open({
          type: "success",
          content: "Informacja została zapisana.",
        });
        setTimeout(() => {
          // Additional logic to be executed after a delay (2 seconds in this case)
          dispatch({
            type: "transactionDataUpdated",
            payload: {
              transactionDataChanged: new Date().getSeconds(),
            },
          });
          setLoading(false);
        }, 2000); // Delay for 2 seconds
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Wystąpiły błedy podczas zapisu umowy.",
        });
        setLoading(false); // Set loading to false even on error
      });
  };

  if (canBeRendered === true) {
    return (
      <>
        {contextHolder}
        <Drawer
          title="Przedmiot niezgodny z umową"
          placement="right"
          onClose={onClose}
          open={open}
        >
          <Space size={30} direction="vertical">
          <Alert
            type="info"
            message="Oznaczenie niezgodności przedmiotu umowy z umową wiąże się z
            uruchomieniem 14-dniowego okresu rozstrzygania sporów w trakcie
            transakcji. W tym czasie strony transakcji będą miały możliwość
            rozwiązać wszelkie niejasności i osiągnąć porozumienie."
          ></Alert>
          
            <TextArea
              value={inspectionNote}
              rows={8}
              placeholder="W kilku zdaniach opisz na czym polega problem..."
              onChange={(e) => setInspectionNote(e.target.value)}
            ></TextArea>
            <Button
              style={{width:"100%"}}
              type="primary"
              danger
              onClick={handleRejectConfirmation}
              loading={loading}
            >
              Zapisz
            </Button>
          </Space>
        </Drawer>
        <Alert
          message="Czy dostarczony przedmiot umowy jest zgodny z umową?"
          description="Weryfikacja zgodności z umową jest istotna, aby upewnić się, że strony przestrzegają uzgodnionych warunków i że dostarczane produkty lub usługi spełniają oczekiwania i standardy określone w umowie."
          type="warning"
          action={
            <Space direction="vertical">
              <Button type="primary" onClick={handleAccept} loading={loading}>
                Tak
              </Button>
              <Button danger ghost onClick={showDrawer} loading={loading}>
                Nie
              </Button>
            </Space>
          }
        />
      </>
    );
  }
};

export default ConfirmInspectionStatusAction;
