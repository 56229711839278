export const agreementTypes = [
    {
        value: `
Niniejsza umowa ('Umowa') zostaje zawarta pomiędzy Stronami na podstawie danych i warunków zawartych w systemie PostPay, który stanowi integralną część tej umowy.

1. Przedmiot umowy:

1.1. Przedmiotem niniejszej umowy jest sprzedaż praw do zarządzania grupą na Facebooku o nazwie: [Nazwa grupy] (dalej zwaną "Grupą") wraz z jej zawartością.

2. Warunki transakcji:

2.1. Szczegółowe warunki transakcji, takie jak cena, forma płatności, terminy oraz inne szczegóły, będą określane i regulowane za pośrednictwem platformy PostPay zgodnie z ustawieniami przeprowadzanej transakcji.

3. Uczciwość wzrostu Grupy:

3.1. Sprzedawca oświadcza i zapewnia, że Grupa na Facebooku nie była sztucznie powiększona za pomocą botów, fałszywych kont lub innych nieuczciwych metod zwiększania liczby członków.

3.2. Sprzedawca jest odpowiedzialny za dostarczenie Kupującemu informacji na temat sposobu uzyskania członków w Grupie, aby potwierdzić uczciwość wzrostu Grupy.

4. Obowiązki stron:

4.1. Strony zobowiązują się do przestrzegania warunków i zasad określonych w ustawieniach transakcji na platformie postpay.pl.

5. Odpowiedzialność stron:

5.1. Strony ponoszą odpowiedzialność za ewentualne naruszenia warunków transakcji lub zasady korzystania z Grupy na Facebooku, które wynikają z ich działań.

6. Brak odpowiedzialności za przyszłe zmiany:

6.1. Sprzedawca nie ponosi odpowiedzialności za ewentualne zmiany wprowadzone na Grupie na Facebooku po zakończeniu transakcji, które mogą wpłynąć na jej działanie lub funkcjonowanie. Kupujący przyjmuje do wiadomości, że Grupa na Facebooku może ulec zmianie w przyszłości z powodu decyzji Facebooka lub administratorów grupy, które są poza kontrolą Sprzedawcy. Sprzedawca nie ponosi odpowiedzialności za takie zmiany i nie jest zobowiązany do ich naprawy ani rekompensaty w związku z nimi.


7. Postanowienia końcowe:

7.1. Niniejsza umowa stanowi porozumienie między stronami w sprawie sprzedaży praw do zarządzania Grupą na Facebooku.

7.2. Wszelkie spory wynikające z umowy będą rozstrzygane zgodnie z obowiązującym regulaminem platformy PostPay oraz obowiązującym prawem.
`,
        label: "Sprzedaż grupy Facebook",
    },
    {
        value:
            `Niniejsza umowa ('Umowa') zostaje zawarta pomiędzy Stronami na podstawie danych i warunków zawartych w systemie PostPay, który stanowi integralną część tej umowy.`,
        label: "Umowa prosta"
    },
    {
        value:
            `
Niniejsza umowa ('Umowa') zostaje zawarta pomiędzy Stronami na podstawie danych i warunków zawartych w systemie PostPay, który stanowi integralną część tej umowy.

1. Przedmiot umowy:

1.1. Przedmiotem niniejszej umowy jest sprzedaż roweru o następujących parametrach:

    - Marka i model: [Marka i model roweru]
    - Numer roweru: [Numer roweru]
    - Stan techniczny: [Stan techniczny roweru]

2. Legalność pochodzenia roweru:

2.1. Sprzedawca oświadcza i zapewnia, że rower, którego przedmiotem jest ta umowa, pochodzi z legalnego źródła i nie jest przedmiotem żadnych nielegalnych działań ani sporów o prawa własności.

3. Odpowiedzialność sprzedawcy:

3.1. Sprzedawca nie ponosi odpowiedzialności za szkody wyrządzone rowerem po jego zakupie przez Kupującego. Kupujący przyjmuje do wiadomości, że używanie roweru niesie ze sobą ryzyko i jest odpowiedzialny za jego bezpieczne i zgodne z przepisami użytkowanie.

4. Obowiązki stron:

4.1. Strony zobowiązują się do przestrzegania warunków określonych w niniejszej umowie.

5. Postanowienia końcowe:

5.1. Niniejsza umowa stanowi porozumienie między stronami w sprawie sprzedaży roweru.

5.2. Wszelkie spory wynikające z umowy będą rozstrzygane zgodnie z obowiązującym regulaminem platformy PostPay oraz obowiązującym prawem.
        `,
        label: "Sprzedaż roweru"
    },
    {
        value:
            `
Niniejsza umowa ('Umowa') zostaje zawarta pomiędzy Stronami na podstawie danych i warunków zawartych w systemie PostPay, który stanowi integralną część tej umowy.

Strony umowy:

Zamawiający:
Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Zamawiającego]
Adres: [Adres Zamawiającego]
Adres e-mail: [Adres e-mail Zamawiającego]
Numer telefonu: [Numer telefonu Zamawiającego]

Firma Wykonawcza:
Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Firmy Wykonawczej]
Adres: [Adres Firmy Wykonawczej]
Adres e-mail: [Adres e-mail Firmy Wykonawczej]
Numer telefonu: [Numer telefonu Firmy Wykonawczej]

Przedmiot umowy:

1. Przedmiotem niniejszej umowy jest zaprojektowanie, stworzenie i wdrożenie sklepu internetowego dla Klienta zgodnie z określonymi specyfikacjami i wymaganiami przedstawionymi w załączniku do umowy (Specyfikacja Sklepu).

2. Specyfikacja Sklepu:

    Załącznik do umowy zawierający szczegółowe specyfikacje i wymagania dotyczące sklepu internetowego, takie jak wygląd, funkcje, integracje, w tym integracja z płatnościami, koszty, terminy i inne szczegóły. Strony dokonały omówienia zakresu prac z załącznikiem i są świadome zakresu prac i zgadzają się na nie.

Warunki umowy:

3. Terminy:

    3.1. Firma Wykonawcza zobowiązuje się do dostarczenia działającego sklepu internetowego zgodnie z Specyfikacją Sklepu w terminie do [Termin wdrożenia sklepu]
    3.2. W przypadku opóźnienia w dostarczeniu sklepu, Klient ma prawo do dochodzenia roszczeń z tytułu ewentualnych szkód wynikłych z opóźnienia ale nie większych niż 10% wartości całego zamówienia.

4. Opłaty:

    4.1. W zamian za dostarczenie sklepu internetowego, Klient zobowiązuje się do zapłaty Firmie Wykonawczej ustalonej opłaty, która wynosi [Koszt stworzenia sklepu] wyrażonej w [Waluta]. 

5. Zaliczka:

    5.1. Strony mogą ustalić wypłatę zaliczki poprzez platformę PostPay w ramach oddzielnej transakcji po zamknięciu pierwszego etapu prac opisanego w warunkach umowy. Wysokość zaliczki oraz warunki jej wypłaty będą ustalone przez Strony w odrębnym porozumieniu.

6. Własność intelektualna:

    6.1. Po zakończeniu prac Firma Wykonawcza przekazuje Klientowi prawa własności intelektualnej do stworzonego sklepu internetowego.

7. Gwarancje:

    7.1. Firma Wykonawcza gwarantuje, że sklep internetowy będzie działać zgodnie z Specyfikacją Sklepu przez okres [Okres Gwarancji] od daty dostawy.
    7.2. W okresie gwarancji Firma Wykonawcza zobowiązuje się do bezzwłocznego usuwania usterek i błędów.

8. Udostępnienie środowiska i informowanie o przebiegu prac:

    8.1. Firma Wykonawcza udostępni Zamawiającemu środowisko do podglądu prac nad sklepem internetowym w trakcie jego tworzenia.
    8.2. Firma Wykonawcza będzie na bieżąco informować Zamawiającego o przebiegu prac i ewentualnych trudnościach.
    8.3. Strony zobowiązują się do kontaktowania się ze sobą za pośrednictwem podanych środków komunikacji, tj. adresów e-mail i numerów telefonów, w celu ustaleń dotyczących realizacji przedmiotu umowy.
    8.4. Strony zobowiązują się do reagowania na potrzeby kontaktu oraz rozwiązywania ewentualnych problemów natury nietechnicznej w sposób współpracujący.

9. Postanowienia końcowe:

    9.1. Niniejsza umowa stanowi porozumienie między Stronami w sprawie wykonania sklepu internetowego.
    9.2. Wszelkie zmiany lub dodatkowe uzgodnienia w ramach tej umowy muszą być zaakceptowane przez obie strony.   
        `,
        label: "Wykonanie sklepu internetowego"
    },
    {
        value:
            `
Niniejsza umowa ('Umowa') zostaje zawarta pomiędzy Stronami na podstawie danych i warunków zawartych w systemie PostPay, który stanowi integralną część tej umowy.

1. Przedmiot umowy:

1.1. Przedmiotem niniejszej umowy jest sprzedaż praw do zarządzania profilem na Instagramie o nazwie: [Nazwa profilu] (dalej zwanym "Profilem") wraz z jego zawartością.

2. Warunki transakcji:

2.1. Szczegółowe warunki transakcji, takie jak cena, forma płatności, terminy oraz inne szczegóły, będą określane i regulowane za pośrednictwem platformy PostPay zgodnie z ustawieniami przeprowadzanej transakcji.

3. Uczciwość wzrostu Profilu:

3.1. Sprzedawca oświadcza i zapewnia, że Profil na Instagramie nie był sztucznie powiększony za pomocą botów, fałszywych kont lub innych nieuczciwych metod zwiększania liczby obserwujących.

3.2. Sprzedawca jest odpowiedzialny za dostarczenie Kupującemu informacji na temat sposobu uzyskania obserwujących w Profilu, aby potwierdzić uczciwość wzrostu Profilu.

4. Obowiązki stron:

4.1. Strony zobowiązują się do przestrzegania warunków i zasad określonych w ustawieniach transakcji na platformie postpay.pl.

5. Odpowiedzialność stron:

5.1. Strony ponoszą odpowiedzialność za ewentualne naruszenia warunków transakcji lub zasady korzystania z Profilu na Instagramie, które wynikają z ich działań.

6. Brak odpowiedzialności za przyszłe zmiany:

6.1. Sprzedawca nie ponosi odpowiedzialności za ewentualne zmiany wprowadzone na Profilu na Instagramie po zakończeniu transakcji, które mogą wpłynąć na jego działanie lub funkcjonowanie. Kupujący przyjmuje do wiadomości, że Profil na Instagramie może ulec zmianie w przyszłości z powodu decyzji Instagrama lub administratora profilu, które są poza kontrolą Sprzedawcy. Sprzedawca nie ponosi odpowiedzialności za takie zmiany i nie jest zobowiązany do ich naprawy ani rekompensaty w związku z nimi.

7. Postanowienia końcowe:

7.1. Niniejsza umowa stanowi porozumienie między stronami w sprawie sprzedaży praw do zarządzania Profilem na Instagramie.

7.2. Wszelkie spory wynikające z umowy będą rozstrzygane zgodnie z obowiązującym regulaminem platformy PostPay oraz obowiązującym prawem.
        
        `,
        label: "Sprzedaż profilu na Instagram"
    },
    {
        value:
            `
Niniejsza umowa ("Umowa") zostaje zawarta pomiędzy Stronami na podstawie danych i warunków zawartych w systemie PostPay, który stanowi integralną część tej umowy.

Strony umowy:

Zamawiający:
Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Zamawiającego]
Adres: [Adres Zamawiającego]
Adres e-mail: [Adres e-mail Zamawiającego]
Numer telefonu: [Numer telefonu Zamawiającego]

Firma Wykonawcza:
Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Firmy Wykonawczej]
Adres: [Adres Firmy Wykonawczej]
Adres e-mail: [Adres e-mail Firmy Wykonawczej]
Numer telefonu: [Numer telefonu Firmy Wykonawczej]

Przedmiot umowy:

1. Przedmiotem niniejszej umowy jest wykonanie robót budowlanych zgodnie z określonymi specyfikacjami i wymaganiami przedstawionymi w załączniku do umowy (Specyfikacja Robót).

2. Specyfikacja Robót:

    Załącznik do umowy zawierający szczegółowe specyfikacje i wymagania dotyczące robót budowlanych, takie jak rodzaj prac, zakres, terminy, koszty i inne szczegóły. Strony dokonały omówienia zakresu prac z załącznikiem i są świadome zakresu prac i zgadzają się na nie.

Warunki umowy:

3. Terminy:

    3.1. Firma Wykonawcza zobowiązuje się do wykonania robót budowlanych zgodnie z Specyfikacją Robót w terminie do [Termin zakończenia robót]
    3.2. W przypadku opóźnienia w wykonaniu robót, Zamawiający ma prawo do dochodzenia roszczeń z tytułu ewentualnych szkód wynikłych z opóźnienia, jednak nie większych niż [Maksymalna kwota kar umownych] lub określona inna wartość, jeśli takie uzgodnienie zostanie uwzględnione w umowie.

4. Opłaty:

    4.1. W zamian za wykonanie robót budowlanych, Zamawiający zobowiązuje się do zapłaty Firmie Wykonawczej ustalonej opłaty, która wynosi [Koszt wykonania robót] wyrażonej w [Waluta]. 

5. Zaliczka:

    5.1. Strony mogą ustalić wypłatę zaliczki poprzez platformę PostPay w ramach oddzielnej transakcji po zamknięciu pierwszego etapu prac opisanego w warunkach umowy. Wysokość zaliczki oraz warunki jej wypłaty będą ustalone przez Strony w odrębnym porozumieniu.

6. Własność intelektualna:

    6.1. Wszelkie prawa własności intelektualnej do stworzonych materiałów lub projektów w ramach robót budowlanych przekazywane są Zamawiającemu, o ile nie ustalono inaczej w umowie.

7. Gwarancje:

    7.1. Firma Wykonawcza gwarantuje, że wykonane prace będą zgodne z Specyfikacją Robót przez okres [Okres Gwarancji] od daty zakończenia prac.
    7.2. W okresie gwarancji Firma Wykonawcza zobowiązuje się do bezzwłocznego usuwania usterek i błędów w pracach budowlanych.

8. Udostępnienie środowiska i informowanie o przebiegu prac:

    8.1. Firma Wykonawcza udostępnia Zamawiającemu możliwość monitorowania postępu robót budowlanych w trakcie ich realizacji.
    8.2. Firma Wykonawcza będzie na bieżąco informować Zamawiającego o przebiegu prac i ewentualnych trudnościach.
    8.3. Strony zobowiązują się do kontaktowania się ze sobą za pośrednictwem podanych środków komunikacji, tj. adresów e-mail i numerów telefonów, w celu ustaleń dotyczących realizacji robót budowlanych.
    8.4. Strony zobowiązują się do reagowania na potrzeby kontaktu oraz rozwiązywania ewentualnych problemów natury nietechnicznej w sposób współpracujący.

9. Postanowienia końcowe:

    9.1. Niniejsza umowa stanowi porozumienie między Stronami w sprawie wykonania robót budowlanych.
    9.2. Wszelkie zmiany lub dodatkowe uzgodnienia w ramach tej umowy muszą być zaakceptowane przez obie strony.
        
        `,
        label: "Wykonanie robót budowlanych"
    },
    {
        value:
            `
Niniejsza umowa ("Umowa") zostaje zawarta pomiędzy Stronami na podstawie danych i warunków zawartych w systemie PostPay, który stanowi integralną część tej umowy.

Strony umowy:

Zamawiający:
Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Zamawiającego]
Adres: [Adres Zamawiającego]
Adres e-mail: [Adres e-mail Zamawiającego]
Numer telefonu: [Numer telefonu Zamawiającego]

Firma Wykonawcza:
Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Firmy Wykonawczej]
Adres: [Adres Firmy Wykonawczej]
Adres e-mail: [Adres e-mail Firmy Wykonawczej]
Numer telefonu: [Numer telefonu Firmy Wykonawczej]

Przedmiot umowy:

1. Przedmiotem niniejszej umowy jest wykonanie modyfikacji w istniejącym sklepie internetowym lub na stronie internetowej [Adres sklepu lub strony] ("Strona") zgodnie z określonymi specyfikacjami i wymaganiami przedstawionymi w załączniku do umowy (Specyfikacja Modyfikacji).

2. Specyfikacja Modyfikacji:

    Załącznik do umowy zawierający szczegółowe specyfikacje i wymagania dotyczące modyfikacji, takie jak rodzaj zmian, zakres, terminy, koszty i inne szczegóły. Strony dokonały omówienia zakresu prac z załącznikiem i są świadome zakresu prac i zgadzają się na nie.

Warunki umowy:

3. Terminy:

    3.1. Firma Wykonawcza zobowiązuje się do wykonania modyfikacji na Stronie zgodnie z Specyfikacją Modyfikacji w terminie do [Termin zakończenia modyfikacji].
    3.2. W przypadku opóźnienia w wykonaniu modyfikacji, Zamawiający ma prawo do dochodzenia roszczeń z tytułu ewentualnych szkód wynikłych z opóźnienia, jednak nie większych niż [Maksymalna kwota kar umownych] lub określona inna wartość, jeśli takie uzgodnienie zostanie uwzględnione w umowie.

4. Opłaty:

    4.1. W zamian za wykonanie modyfikacji, Zamawiający zobowiązuje się do zapłaty Firmie Wykonawczej ustalonej opłaty, która wynosi [Koszt wykonania modyfikacji] wyrażonej w [Waluta]. 

5. Zaliczka:

    5.1. Strony mogą ustalić wypłatę zaliczki poprzez platformę PostPay w ramach oddzielnej transakcji po zamknięciu pierwszego etapu prac opisanego w warunkach umowy. Wysokość zaliczki oraz warunki jej wypłaty będą ustalone przez Strony w odrębnym porozumieniu.

6. Własność intelektualna:

    6.1. Wszelkie prawa własności intelektualnej do stworzonych materiałów lub projektów w ramach modyfikacji przekazywane są Zamawiającemu, o ile nie ustalono inaczej w umowie.

7. Gwarancje:

    7.1. Firma Wykonawcza gwarantuje, że wykonane modyfikacje będą zgodne z Specyfikacją Modyfikacji przez okres [Okres Gwarancji] od daty zakończenia prac.
    7.2. W okresie gwarancji Firma Wykonawcza zobowiązuje się do bezzwłocznego usuwania usterek i błędów w modyfikacjach.

8. Udostępnienie środowiska i informowanie o przebiegu prac:

    8.1. Firma Wykonawcza udostępnia Zamawiającemu możliwość monitorowania postępu modyfikacji w trakcie ich realizacji.
    8.2. Firma Wykonawcza będzie na bieżąco informować Zamawiającego o przebiegu prac i ewentualnych trudnościach.
    8.3. Strony zobowiązują się do kontaktowania się ze sobą za pośrednictwem podanych środków komunikacji, tj. adresów e-mail i numerów telefonów, w celu ustaleń dotyczących realizacji modyfikacji.
    8.4. Strony zobowiązują się do reagowania na potrzeby kontaktu oraz rozwiązywania ewentualnych problemów natury nietechnicznej w sposób współpracujący.

9. Postanowienia końcowe:

    9.1. Niniejsza umowa stanowi porozumienie między Stronami w sprawie wykonania modyfikacji na Stronie.
    9.2. Wszelkie zmiany lub dodatkowe uzgodnienia w ramach tej umowy muszą być zaakceptowane przez obie strony.
        `,
        label: "Wykonanie modyfikacji w sklepie internetowym lub stronie www"
    },
    {
        value:
            `
Niniejsza umowa ("Umowa") zostaje zawarta pomiędzy Stronami na podstawie danych i warunków zawartych w systemie PostPay, który stanowi integralną część tej umowy.

Strony umowy:

Zamawiający:
Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Zamawiającego]
Adres: [Adres Zamawiającego]
Adres e-mail: [Adres e-mail Zamawiającego]
Numer telefonu: [Numer telefonu Zamawiającego]

Firma Wykonawcza:
Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Firmy Wykonawczej]
Adres: [Adres Firmy Wykonawczej]
Adres e-mail: [Adres e-mail Firmy Wykonawczej]
Numer telefonu: [Numer telefonu Firmy Wykonawczej]

Przedmiot umowy:

1. Przedmiotem niniejszej umowy jest wykonanie logo, plakatu i grafiki ("Materiały") zgodnie z określonymi specyfikacjami i wymaganiami przedstawionymi w załączniku do umowy (Specyfikacja Materiałów).

2. Specyfikacja Materiałów:

    Załącznik do umowy zawierający szczegółowe specyfikacje i wymagania dotyczące Materiałów, takie jak rodzaj, koncepcja, kolory, formaty, terminy, koszty i inne szczegóły. Strony dokonały omówienia zakresu prac z załącznikiem i są świadome zakresu prac i zgadzają się na nie.

Warunki umowy:

3. Terminy:

    3.1. Firma Wykonawcza zobowiązuje się do dostarczenia Materiałów zgodnie z Specyfikacją Materiałów w terminie do [Termin dostawy Materiałów].
    3.2. W przypadku opóźnienia w dostarczeniu Materiałów, Zamawiający ma prawo do dochodzenia roszczeń z tytułu ewentualnych szkód wynikłych z opóźnienia, jednak nie większych niż [Maksymalna kwota kar umownych] lub określona inna wartość, jeśli takie uzgodnienie zostanie uwzględnione w umowie.

4. Opłaty:

    4.1. W zamian za wykonanie Materiałów, Zamawiający zobowiązuje się do zapłaty Firmie Wykonawczej ustalonej opłaty, która wynosi [Koszt wykonania Materiałów] wyrażonej w [Waluta]. 

5. Własność intelektualna:

    5.1. Wszelkie prawa własności intelektualnej do wykonanych Materiałów przekazywane są Zamawiającemu, o ile nie ustalono inaczej w umowie.

6. Gwarancje:

    6.1. Firma Wykonawcza gwarantuje, że wykonane Materiały będą zgodne z Specyfikacją Materiałów.
    6.2. W okresie gwarancji Firma Wykonawcza zobowiązuje się do bezzwłocznego usuwania usterek i błędów w Materiałach.

7. Udostępnienie środowiska i informowanie o przebiegu prac:

    7.1. Firma Wykonawcza udostępnia Zamawiającemu możliwość monitorowania postępu prac nad Materiałami w trakcie ich realizacji.
    7.2. Firma Wykonawcza będzie na bieżąco informować Zamawiającego o przebiegu prac i ewentualnych trudnościach.
    7.3. Strony zobowiązują się do kontaktowania się ze sobą za pośrednictwem podanych środków komunikacji, tj. adresów e-mail i numerów telefonów, w celu ustaleń dotyczących realizacji Materiałów.
    7.4. Strony zobowiązują się do reagowania na potrzeby kontaktu oraz rozwiązywania ewentualnych problemów natury nietechnicznej w sposób współpracujący.

8. Postanowienia końcowe:

    8.1. Niniejsza umowa stanowi porozumienie między Stronami w sprawie wykonania Materiałów.
    8.2. Wszelkie zmiany lub dodatkowe uzgodnienia w ramach tej umowy muszą być zaakceptowane przez obie strony.
        
        `,
        label: "Wykonanie logo, plakatu i grafiki"
    },
    {
        value:
            `
Niniejsza umowa ("Umowa") zostaje zawarta pomiędzy Stronami na podstawie danych i warunków zawartych w systemie PostPay, który stanowi integralną część tej umowy.

Strony umowy:

Zamawiający:
Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Zamawiającego]
Adres: [Adres Zamawiającego]
Adres e-mail: [Adres e-mail Zamawiającego]
Numer telefonu: [Numer telefonu Zamawiającego]

Firma Wykonawcza:
Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Firmy Wykonawczej]
Adres: [Adres Firmy Wykonawczej]
Adres e-mail: [Adres e-mail Firmy Wykonawczej]
Numer telefonu: [Numer telefonu Firmy Wykonawczej]

Przedmiot umowy:

1. Przedmiotem niniejszej umowy jest przeprowadzenie audytu SEO ("Audyt") na stronie internetowej lub platformie internetowej [Adres sklepu lub strony].

2. Zakres Audytu:

    Audyt obejmuje analizę strony internetowej lub platformy pod kątem optymalizacji pod kątem wyszukiwarek (SEO). Zakres i szczegóły Audytu zostały określone przez Strony i zawarte w załączniku do umowy (Specyfikacja Audytu).

3. Wynik prac:

    Rezultatem Audytu jest dostarczenie Zamawiającemu listy zaleceń i modyfikacji, które pozwolą na poprawę wskaźników opisanych w Specyfikacji Audytu.

Warunki umowy:

4. Terminy:

    4.1. Firma Wykonawcza zobowiązuje się do dostarczenia raportu z przeprowadzonego Audytu w terminie do [Termin dostarczenia raportu].
    4.2. W przypadku opóźnienia w dostarczeniu raportu, Zamawiający ma prawo do dochodzenia roszczeń z tytułu ewentualnych szkód wynikłych z opóźnienia, jednak nie większych niż [Maksymalna kwota kar umownych] lub określona inna wartość, jeśli takie uzgodnienie zostanie uwzględnione w umowie.

5. Opłaty:

    5.1. W zamian za przeprowadzenie Audytu, Zamawiający zobowiązuje się do zapłaty Firmie Wykonawczej ustalonej opłaty, która wynosi [Koszt Audytu] wyrażonej w [Waluta]. 

6. Własność intelektualna:

    6.1. Raport z Audytu oraz wszelkie stworzone przez Firmę Wykonawczą materiały i wyniki Audytu przekazywane są Zamawiającemu, o ile nie ustalono inaczej w umowie.

7. Gwarancje:

    7.1. Firma Wykonawcza gwarantuje, że Audyt zostanie przeprowadzony z należytą starannością i zgodnie z branżowymi standardami.

8. Udostępnienie środowiska i informowanie o przebiegu prac:

    8.1. Firma Wykonawcza może wymagać dostępu do serwera i ustawień strony internetowej lub platformy w celu przeprowadzenia Audytu.
    8.2. Firma Wykonawcza będzie na bieżąco informować Zamawiającego o postępie Audytu oraz przekazywać raport końcowy.

9. Postanowienia końcowe:

    9.1. Niniejsza umowa stanowi porozumienie między Stronami w sprawie przeprowadzenia Audytu SEO.
    9.2. Wszelkie zmiany lub dodatkowe uzgodnienia w ramach tej umowy muszą być zaakceptowane przez obie strony.
        
        `,
        label: "Wykonanie audytu seo"
    },
    {
        value:
            `
Niniejsza umowa ("Umowa") zostaje zawarta pomiędzy Stronami na podstawie danych i warunków zawartych w systemie PostPay, który stanowi integralną część tej umowy.

Strony umowy:

Zamawiający:
Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Zamawiającego]
Adres: [Adres Zamawiającego]
Adres e-mail: [Adres e-mail Zamawiającego]
Numer telefonu: [Numer telefonu Zamawiającego]

Firma Wykonawcza:
Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Firmy Wykonawczej]
Adres: [Adres Firmy Wykonawczej]
Adres e-mail: [Adres e-mail Firmy Wykonawczej]
Numer telefonu: [Numer telefonu Firmy Wykonawczej]

Przedmiot umowy:

1. Przedmiotem niniejszej umowy jest przeprowadzenie audytu technicznego strony internetowej [Adres strony] ("Strona") w celu zidentyfikowania błędów, które wpływają na jej wydajność oraz przygotowanie listy zaleceń i modyfikacji w celu przyspieszenia strony i wyeliminowania istniejących błędów.

2. Zakres Audytu:

    Audyt obejmuje analizę techniczną Strony pod kątem wydajności, responsywności oraz wykrywanie błędów, które wpływają na użytkowników. Zakres i szczegóły Audytu zostały określone przez Strony i zawarte w załączniku do umowy (Specyfikacja Audytu).

3. Wynik prac:

    Rezultatem Audytu jest dostarczenie Zamawiającemu listy modyfikacji i zaleceń technicznych, które pomogą w przyspieszeniu Strony i wyeliminowaniu błędów opisanych w Specyfikacji Audytu.

Warunki umowy:

4. Terminy:

    4.1. Firma Wykonawcza zobowiązuje się do dostarczenia raportu z przeprowadzonego Audytu w terminie do [Termin dostarczenia raportu].
    4.2. W przypadku opóźnienia w dostarczeniu raportu, Zamawiający ma prawo do dochodzenia roszczeń z tytułu ewentualnych szkód wynikłych z opóźnienia, jednak nie większych niż [Maksymalna kwota kar umownych] lub określona inna wartość, jeśli takie uzgodnienie zostanie uwzględnione w umowie.

5. Opłaty:

    5.1. W zamian za przeprowadzenie Audytu, Zamawiający zobowiązuje się do zapłaty Firmie Wykonawczej ustalonej opłaty, która wynosi [Koszt Audytu] wyrażonej w [Waluta]. 

6. Własność intelektualna:

    6.1. Raport z Audytu oraz wszelkie stworzone przez Firmę Wykonawczą materiały i wyniki Audytu przekazywane są Zamawiającemu, o ile nie ustalono inaczej w umowie.

7. Gwarancje:

    7.1. Firma Wykonawcza gwarantuje, że Audyt zostanie przeprowadzony z należytą starannością i zgodnie z branżowymi standardami.

8. Udostępnienie środowiska i informowanie o przebiegu prac:

    8.1. Firma Wykonawcza może wymagać dostępu do serwera i ustawień Strony w celu przeprowadzenia Audytu.
    8.2. Firma Wykonawcza będzie na bieżąco informować Zamawiającego o postępie Audytu oraz przekazywać raport końcowy.

9. Postanowienia końcowe:

    9.1. Niniejsza umowa stanowi porozumienie między Stronami w sprawie przeprowadzenia Audytu technicznego Strony.
    9.2. Wszelkie zmiany lub dodatkowe uzgodnienia w ramach tej umowy muszą być zaakceptowane przez obie strony.
        
        `,
        label: "Wykonanie audytu technicznego strony"
    },
    {
        value:
            `
Niniejsza umowa ("Umowa") zostaje zawarta pomiędzy Stronami na podstawie danych i warunków zawartych w systemie PostPay, który stanowi integralną część tej umowy.

Strony umowy:

Zamawiający:
Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Zamawiającego]
Adres: [Adres Zamawiającego]
Adres e-mail: [Adres e-mail Zamawiającego]
Numer telefonu: [Numer telefonu Zamawiającego]

Firma Wykonawcza:
Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Firmy Wykonawczej]
Adres: [Adres Firmy Wykonawczej]
Adres e-mail: [Adres e-mail Firmy Wykonawczej]
Numer telefonu: [Numer telefonu Firmy Wykonawczej]

Przedmiot umowy:

1. Przedmiotem niniejszej umowy jest dostarczenie [Ilość tekstów] tekstów zoptymalizowanych pod kątem wyszukiwarek (SEO) ("Teksty") dla Zamawiającego.

2. Specyfikacja Tekstów:

    Specyfikacja Tekstów obejmuje szczegółowy opis tematów, słów kluczowych oraz wymagań dotyczących każdego z Tekstów. Specyfikacja Tekstów jest dostarczana przez Zamawiającego Firmie Wykonawczej przed rozpoczęciem prac.

Warunki umowy:

3. Terminy:

    3.1. Firma Wykonawcza zobowiązuje się dostarczyć wszystkie Teksty w terminie do [Termin dostarczenia Tekstów].
    3.2. W przypadku opóźnienia w dostarczeniu Tekstów, Zamawiający ma prawo do dochodzenia roszczeń z tytułu ewentualnych szkód wynikłych z opóźnienia, jednak nie większych niż [Maksymalna kwota kar umownych] lub określona inna wartość, jeśli takie uzgodnienie zostanie uwzględnione w umowie.

4. Opłaty:

    4.1. W zamian za dostarczenie Tekstów SEO, Zamawiający zobowiązuje się do zapłaty Firmie Wykonawczej ustalonej opłaty, która wynosi [Koszt dostarczenia Tekstów] wyrażonej w [Waluta]. 

5. Własność intelektualna:

    5.1. Po zapłaceniu opłaty, prawa autorskie do dostarczonych Tekstów przechodzą na Zamawiającego.

6. Gwarancje:

    6.1. Firma Wykonawcza gwarantuje, że dostarczone Teksty będą zoptymalizowane pod kątem SEO zgodnie z wymaganiami Specyfikacji Tekstów.

7. Postanowienia końcowe:

    7.1. Niniejsza umowa stanowi porozumienie między Stronami w sprawie dostarczenia Tekstów SEO.
    7.2. Wszelkie zmiany lub dodatkowe uzgodnienia w ramach tej umowy muszą być zaakceptowane przez obie strony.
        
        `,
        label: "Dostarczenie tekstów seo"
    },
    {
        value:
            `
        Niniejsza umowa ("Umowa") zostaje zawarta pomiędzy Stronami na podstawie danych i warunków zawartych w systemie PostPay, który stanowi integralną część tej umowy.

        Strony umowy:
        
        Zamawiający:
        Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Zamawiającego]
        Adres: [Adres Zamawiającego]
        Adres e-mail: [Adres e-mail Zamawiającego]
        Numer telefonu: [Numer telefonu Zamawiającego]
        
        Firma Wykonawcza:
        Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Firmy Wykonawczej]
        Adres: [Adres Firmy Wykonawczej]
        Adres e-mail: [Adres e-mail Firmy Wykonawczej]
        Numer telefonu: [Numer telefonu Firmy Wykonawczej]
        
        Przedmiot umowy:
        
        1. Przedmiotem niniejszej umowy jest wykonanie usług aktualizacyjnych/poprawkowych na serwerze lub serwerach [Nazwa serwera lub serwerów] ("Serwer").
        
        2. Zakres Usług Aktualizacyjnych/Poprawkowych jest opisany w Załączniku do umowy, który określa konkretne modyfikacje, poprawki i aktualizacje do przeprowadzenia. Załącznik jest znany stronom.
        
        Warunki umowy:
        
        3. Terminy:

           3.1. Usługi Aktualizacyjne/Poprawkowe zostaną przeprowadzone zgodnie z harmonogramem określonym w Załączniku do umowy. Firma Wykonawcza zobowiązuje się do przestrzegania tych terminów.
           3.2. W przypadku opóźnienia w realizacji Usług Aktualizacyjnych/Poprawkowych, Firma Wykonawcza powinna poinformować Zamawiającego o przyczynach opóźnienia i zaproponować nowy termin.
        
        4. Opłaty:

           4.1. W zamian za przeprowadzenie Usług Aktualizacyjnych/Poprawkowych, Zamawiający zobowiązuje się do zapłaty Firmie Wykonawczej ustalonej opłaty, która wynosi [Koszt Usług Aktualizacyjnych/Poprawkowych] wyrażonej w [Waluta].
        
        5. Dostęp do Serwera:

           5.1. W celu przeprowadzenia Usług Aktualizacyjnych/Poprawkowych, Firma Wykonawcza może wymagać dostępu do Serwera oraz ustawień technicznych. Strony zobowiązują się do zapewnienia niezbędnego dostępu oraz informacji niezbędnych do przeprowadzenia Usług Aktualizacyjnych/Poprawkowych.
        
        6. Własność intelektualna:

           6.1. Wszelkie modyfikacje, poprawki i aktualizacje przeprowadzone na Serwerze w ramach Usług Aktualizacyjnych/Poprawkowych pozostają własnością Zamawiającego.
        
        7. Gwarancje:

           7.1. Firma Wykonawcza gwarantuje, że Usługi Aktualizacyjne/Poprawkowe zostaną wykonane z należytą starannością i zgodnie z branżowymi standardami.
        
           7.2. Firma Wykonawcza bierze pełną odpowiedzialność za poprawne funkcjonowanie Serwera po wprowadzeniu modyfikacji przez okres [Ilość dni] od daty wprowadzenia modyfikacji. W przypadku jakichkolwiek usterek lub problemów technicznych, które wystąpią w okresie gwarancji, Firma Wykonawcza zobowiązuje się do ich bezzwłocznego rozwiązania na własny koszt.
        
        8. Zmiana zakresu prac:

           8.1. Zmiany w zakresie Usług Aktualizacyjnych/Poprawkowych mogą zostać wprowadzone tylko na piśmie i muszą być zaakceptowane przez obie Strony w formie aneksu do umowy.
        
        9. Czas niedostępności Serwera:

           9.1. W przypadku, gdy Usługi Aktualizacyjne/Poprawkowe wymagają wyłączenia Serwera lub mogą utrudnić dostęp do serwisów, stron internetowych lub usług hostowanych na Serwerze, Strony zobowiązują się do wcześniejszego uzgodnienia czasu niedostępności usługi oraz podejmują działania w celu minimalizacji ewentualnych niedogodności dla użytkowników Serwera.
        
        10. Postanowienia końcowe:

            10.1. Niniejsza umowa stanowi porozumienie między Stronami w sprawie przeprowadzenia Usług Aktualizacyjnych/Poprawkowych Serwera.
            10.2. Wszelkie zmiany lub dodatkowe uzgodnienia w ramach tej umowy muszą być zaakceptowane przez obie Strony.
        
        `,
        label: "Usługi aktualizacyjne/poprawkowe serwera"
    },
    {
        value:
            `
Niniejsza umowa ("Umowa") zostaje zawarta pomiędzy Stronami na podstawie danych i warunków zawartych w systemie PostPay, który stanowi integralną część tej umowy.

1. Strony umowy:

Sprzedający:

Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Sprzedającego]
Adres: [Adres Sprzedającego]
Adres e-mail: [Adres e-mail Sprzedającego]
Numer telefonu: [Numer telefonu Sprzedającego]

Nabywca:

Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Nabywcy]
Adres: [Adres Nabywcy]
Adres e-mail: [Adres e-mail Nabywcy]
Numer telefonu: [Numer telefonu Nabywcy]

2. Przedmiot umowy:

Przedmiotem niniejszej umowy jest sprzedaż sklepu internetowego o nazwie [Nazwa Sklepu] ("Sklep") wraz z wszystkimi jego aktywami, treściami i prawami związanymi z prowadzeniem tego Sklepu.

Zakres sprzedaży obejmuje:

a. Właściciel Sklepu przekazuje Nabywcy prawa własności do domeny, na której działa Sklep.
b. Wszystkie treści, zawartość, grafiki, zdjęcia, kody źródłowe, bazy danych, konta użytkowników oraz wszelkie inne zasoby i aktywa związane z funkcjonowaniem Sklepu.
c. Umowę z dostawcami, jeśli taka istnieje i może być przeniesiona na Nabywcę.
d. Przekazanie wglądu w bieżący stan prowadzenia działalności Sklepu, w tym raporty, informacje o klientach, statystyki itp.

W związku z przekazaniem Sklepu, Sprzedający zobowiązuje się do niezwłocznego wycofania się z działalności związanej z tym Sklepem i do przekazania wszelkich niezbędnych informacji oraz pomocy w procesie przeniesienia własności i zarządzania Sklepem na Nabywcę.

3. Warunki umowy:

Cena:

3.1. Cena sprzedaży Sklepu wynosi [Cena] wyrażona w [Waluta]. Strony ustalają, że cena ta obejmuje pełny koszt nabycia i przeniesienia praw własności oraz aktywów Sklepu na Nabywcę.

4. Przeniesienie własności:

4.1. Przeniesienie praw własności do Sklepu i związanego z nim mienia odbędzie się w terminie [Termin przeniesienia] od daty podpisania niniejszej umowy.
4.2. W chwili przeniesienia praw własności, Sprzedający nie będzie posiadać żadnych praw ani interesów w związku z tym Sklepem.

5. Współpraca po sprzedaży:

5.1. Sprzedający zobowiązuje się do udzielenia Nabywcy niezbędnej pomocy w okresie [Okres pomocy po sprzedaży] od daty przeniesienia własności w celu ułatwienia płynnego przejęcia i zarządzania Sklepem.

6. Ujawnienie informacji:

6.1. Strony zobowiązują się do zachowania poufności w odniesieniu do wszelkich informacji i dokumentów przekazywanych w związku z niniejszą umową.

7. Gwarancje:

7.1. Sprzedający gwarantuje, że ma pełne prawo do sprzedaży Sklepu i nie ma żadnych trzecich stron, które mogą rościć sobie prawa do Sklepu lub jego aktywów.
7.2. Nabywca akceptuje Sklep w stanie "takim, jaki jest" i rozumie, że Sprzedający nie udziela żadnych innych gwarancji, ani wyraźnych, ani dorozumianych, dotyczących Sklepu lub jego potencjału.

8. Zmiana nazwy domeny:

8.1. W ramach niniejszej umowy, Sprzedający zgadza się na zmianę nazwy domeny Sklepu z [Obecna nazwa domeny] na [Nowa nazwa domeny]. Koszty i procedury związane z tą zmianą będą ponoszone przez Nabywcę.

9. Postanowienia końcowe:

9.1. Niniejsza umowa stanowi porozumienie między Stronami w sprawie sprzedaży Sklepu internetowego.
9.2. Wszelkie zmiany lub dodatkowe uzgodnienia w ramach tej umowy muszą być zaakceptowane przez obie Strony.
        
        `,
        label: "Sprzedaż sklepu internetowego"
    },
    {
        value:
            `
Niniejsza umowa ("Umowa") zostaje zawarta pomiędzy Stronami na podstawie danych i warunków zawartych w systemie PostPay, który stanowi integralną część tej umowy.

1. Strony umowy:

Twórca (Wykonawca):

- Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Twórcy]
- Adres: [Adres Twórcy]
- Adres e-mail: [Adres e-mail Twórcy]
- Numer telefonu: [Numer telefonu Twórcy]

Klient (Nabywca):

- Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Klienta]
- Adres: [Adres Klienta]
- Adres e-mail: [Adres e-mail Klienta]
- Numer telefonu: [Numer telefonu Klienta]

2. Przedmiot umowy:

Przedmiotem niniejszej umowy jest wykonanie rękodzieła lub dzieła artystycznego, zwane dalej "Rękodziełem", przez Twórcę na rzecz Klienta zgodnie z określonymi specyfikacjami i wymaganiami przedstawionymi w załączniku do umowy (Specyfikacja Rękodzieła).

3. Warunki umowy:

3. Terminy:
3.1. Twórca zobowiązuje się do dostarczenia Rękodzieła zgodnie z Specyfikacją Rękodzieła w terminie do [Termin dostarczenia Rękodzieła].
3.2. W przypadku opóźnienia w dostarczeniu Rękodzieła, Klient ma prawo do dochodzenia roszczeń z tytułu ewentualnych szkód wynikłych z opóźnienia.

4. Cena:
4.1. W zamian za dostarczenie Rękodzieła, Klient zobowiązuje się do zapłaty Twórcy ustalonej opłaty, która wynosi [Cena Rękodzieła] wyrażonej w [Waluta].

5. Własność intelektualna:
5.1. Po zakończeniu prac Twórca przekazuje Klientowi prawa własności intelektualnej do wykonanego Rękodzieła, z wyłączeniem wszelkich praw do ewentualnych materiałów źródłowych lub projektowych.

6. Gwarancje:
6.1. Twórca gwarantuje, że Rękodzieło będzie zgodne z Specyfikacją Rękodzieła oraz wolne od wad materiałowych i wykonawczych.

7. Ujawnienie informacji:
7.1. Strony zobowiązują się do zachowania poufności w odniesieniu do wszelkich informacji i dokumentów przekazywanych w związku z niniejszą umową.

8. Postanowienia końcowe:
8.1. Niniejsza umowa stanowi porozumienie między Stronami w sprawie wykonania Rękodzieła.
8.2. Wszelkie zmiany lub dodatkowe uzgodnienia w ramach tej umowy muszą być zaakceptowane przez obie Strony.
        `,
        label:
            `Wykonanie rękodzieła`
    },
    {
        value:
            `
Niniejsza umowa ("Umowa") zostaje zawarta pomiędzy Stronami na podstawie danych i warunków zawartych w systemie PostPay, który stanowi integralną część tej umowy.

Strony umowy:

1. Sprzedawca:

    - Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Sprzedawcy]
    - Adres: [Adres Sprzedawcy]
    - Numer telefonu: [Numer telefonu Sprzedawcy]
    - Adres e-mail: [Adres e-mail Sprzedawcy]

2. Kupujący:

    - Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Kupującego]
    - Adres: [Adres Kupującego]
    - Numer telefonu: [Numer telefonu Kupującego]
    - Adres e-mail: [Adres e-mail Kupującego]

Strony są dalej łącznie nazywane "Stronami".

Przedmiot umowy:

Przedmiotem niniejszej umowy jest sprzedaż następujących antyków (dalej nazywanych "Antyki"):
(Opis antyków, w tym nazwa, krótka charakteryzacja, zdjęcia, numery ewidencyjne, itp.)

Wszystkie Antyki zostały zweryfikowane pod kątem ich autentyczności oraz pochodzenia i posiadają odpowiednie poświadczenia i certyfikaty.

Warunki umowy:

3. Cena i warunki płatności:

    3.1. Cena sprzedaży Antyków wynosi [Cena Antyków] wyrażona w [Waluta].
    3.2. Kupujący zobowiązuje się do zapłacenia ceny sprzedaży Antyków za pośrednictwem systemu PostPay, na rachunek bankowy Sprzedawcy zgodnie z warunkami ustalonymi w systemie PostPay.

4. Poświadczenia i certyfikaty:

    4.1. Sprzedawca zobowiązuje się dostarczyć Kupującemu wszystkie niezbędne poświadczenia i certyfikaty potwierdzające autentyczność i właścicielstwo Antyków.
    4.2. Kupujący ma prawo do zweryfikowania autentyczności Antyków przez niezależnych ekspertów i organizacje, a wszelkie koszty związane z taką weryfikacją pokrywa Kupujący.

5. Gwarancje:

    5.1. Sprzedawca gwarantuje, że Antyki są autentyczne i zgodne z opisem oraz nie naruszają praw osób trzecich.
    5.2. Kupujący ma prawo do zwrócenia Antyków i uzyskania pełnego zwrotu ceny zakupu, jeśli okaże się, że Antyki są fałszywe lub niezgodne z opisem, pod warunkiem, że zawiadomi Sprzedawcę w ciągu [Liczba dni] dni od daty odkrycia niezgodności.

6. Przeniesienie własności:

    6.1. Własność nad Antykami zostanie przeniesiona z chwilą ostatecznej zapłaty przez Kupującego.

7. Odpowiedzialność Stron:

    7.1. Strony ponoszą odpowiedzialność za ewentualne naruszenia warunków umowy lub zasady korzystania z Antyków, które wynikają z ich działań.

8. Postanowienia końcowe:

    8.1. Niniejsza umowa stanowi porozumienie między Stronami w sprawie sprzedaży Antyków z poświadczeniem autentyczności przy wykorzystaniu systemu PostPay.
    8.2. Wszelkie zmiany lub dodatkowe uzgodnienia w ramach tej umowy muszą być zaakceptowane przez obie strony.
        `,
        label:
            `Sprzedaż antyków z poświadczeniem autentyczności`
    }, {

        value:
            `
Niniejsza umowa ("Umowa") zostaje zawarta pomiędzy Stronami na podstawie danych i warunków zawartych w systemie PostPay, który stanowi integralną część tej umowy.

Strony umowy:

1. Sprzedawca (Osoba prywatna):

    - Imię i nazwisko: [Imię i nazwisko Sprzedawcy]
    - Adres: [Adres Sprzedawcy]
    - Numer dowodu osobistego: [Numer dowodu osobistego Sprzedawcy]
    - Numer telefonu: [Numer telefonu Sprzedawcy]

2. Kupujący (Osoba prywatna):

    - Imię i nazwisko: [Imię i nazwisko Kupującego]
    - Adres: [Adres Kupującego]
    - Numer dowodu osobistego: [Numer dowodu osobistego Kupującego]
    - Numer telefonu: [Numer telefonu Kupującego]

Strony są dalej łącznie nazywane "Stronami".

3. Przedmiot umowy:

Przedmiotem niniejszej umowy jest sprzedaż następującego pojazdu:

- Marka i model: [Marka i model pojazdu]
- Numer rejestracyjny: [Numer rejestracyjny pojazdu]
- Numer nadwozia (VIN): [Numer VIN pojazdu]
- Numer silnika: [Numer silnika pojazdu]
- Rok produkcji: [Rok produkcji pojazdu]

Sprzedawca oświadcza, że przedmiotowy pojazd jest w jego własności, jest wolny od obciążeń, nie jest przedmiotem jakichkolwiek roszczeń osób trzecich i jest zdatny do sprzedaży.

Sprzedawca oświadcza, że stan techniczny pojazdu jest zgodny z opisem zawartym w ogłoszeniu sprzedaży, a przebieg pojazdu jest autentyczny i nie był manipulowany.

Warunki umowy:

4. Cena i warunki płatności:

    4.1. Cena sprzedaży pojazdu wynosi [Cena pojazdu] wyrażona w [Waluta].
    4.2. Kupujący zobowiązuje się do zapłaty ceny sprzedaży pojazdu w gotówce przed przekazaniem pojazdu.

5. Autentyczność przebiegu i stanu technicznego:

    5.1. Sprzedawca gwarantuje autentyczność przebiegu i stanu technicznego pojazdu i zobowiązuje się dostarczyć wszelkie dokumenty i dowody potwierdzające te informacje.

6. Prawo do sprawdzenia autentyczności:

    6.1. Kupujący ma prawo, przed ostatecznym przyjęciem pojazdu, sprawdzić autentyczność przebiegu i stanu technicznego pojazdu za pomocą niezależnego eksperta lub serwisu samochodowego. W przypadku wykrycia niezgodności z umową, Kupujący ma prawo zwrócić pojazd i żądać zwrotu wpłaty, wraz z kosztami poniesionymi na przeprowadzenie ekspertyzy.

7. Odpowiedzialność Stron:

    7.1. Strony ponoszą odpowiedzialność za ewentualne naruszenia warunków umowy lub zasady korzystania z pojazdu, które wynikają z ich działań.

8. Przeniesienie własności:

    8.1. Własność nad pojazdem zostanie przeniesiona z chwilą ostatecznej zapłaty przez Kupującego.

9. Postanowienia końcowe:

    9.1. Niniejsza umowa stanowi porozumienie między Stronami w sprawie sprzedaży pojazdu z uwzględnieniem autentyczności przebiegu i stanu technicznego.
    9.2. Wszelkie zmiany lub dodatkowe uzgodnienia w ramach tej umowy muszą być zaakceptowane przez obie strony.
            
            `,
        label:
            `Sprzedaż samochodu`
    },
    {
        value: `
Niniejsza umowa ("Umowa") zostaje zawarta pomiędzy Stronami na podstawie danych i warunków zawartych w systemie PostPay, który stanowi integralną część tej umowy.

Strony umowy:

1. Sprzedawca (Osoba prywatna):

    - Imię i nazwisko: [Imię i nazwisko Sprzedawcy]
    - Adres: [Adres Sprzedawcy]
    - Numer dowodu osobistego: [Numer dowodu osobistego Sprzedawcy]
    - Numer telefonu: [Numer telefonu Sprzedawcy]

2. Kupujący (Osoba prywatna lub Firma):

    - Imię i nazwisko/nazwa firmy: [Imię i nazwisko/nazwa firmy Kupującego]
    - Adres: [Adres Kupującego]
    - Numer identyfikacji podatkowej (NIP) lub numer identyfikacji podatkowej VAT (jeśli dotyczy): [Numer NIP lub VAT Kupującego]
    - Numer telefonu: [Numer telefonu Kupującego]

Strony są dalej łącznie nazywane "Stronami".

3. Przedmiot umowy:

Przedmiotem niniejszej umowy jest sprzedaż następującej koparki:

- Marka i model koparki: [Marka i model koparki]
- Numer seryjny koparki: [Numer seryjny koparki]
- Rok produkcji koparki: [Rok produkcji koparki]

Sprzedawca oświadcza, że koparka jest w jego własności, jest wolna od obciążeń, nie jest przedmiotem jakichkolwiek roszczeń osób trzecich i jest zdatna do sprzedaży.

Sprzedawca gwarantuje, że koparka jest w pełni sprawna, zgodna z opisem i stanem technicznym zawartym w ogłoszeniu sprzedaży.

4. Cena i warunki płatności:

    4.1. Cena sprzedaży koparki wynosi [Cena koparki] wyrażona w [Waluta].
    4.2. Kupujący zobowiązuje się do zapłaty ceny sprzedaży koparki w terminie do [Termin płatności].

5. Przeniesienie własności:

    5.1. Własność nad koparką zostanie przeniesiona z chwilą dokonania ostatecznej zapłaty przez Kupującego.

6. Gwarancja:

    6.1. Sprzedawca gwarantuje, że koparka jest w pełni sprawna w chwili jej sprzedaży i nie ma znanych wad ukrytych. Gwarancja obejmuje okres [Okres gwarancji] od daty zawarcia umowy.

7. Odbiór koparki:

    7.1. Kupujący zobowiązuje się odebrać koparkę w miejscu wskazanym przez Sprzedawcę w terminie do [Termin odbioru]. Odbiór koparki odbywa się na koszt i ryzyko Kupującego.

8. Odpowiedzialność Stron:

    8.1. Strony ponoszą odpowiedzialność za ewentualne naruszenia warunków umowy lub zasady korzystania z koparki, które wynikają z ich działań.

9. Postanowienia końcowe:

    9.1. Niniejsza umowa stanowi porozumienie między Stronami w sprawie sprzedaży koparki.
    9.2. Wszelkie zmiany lub dodatkowe uzgodnienia w ramach tej umowy muszą być zaakceptowane przez obie strony.
        `,
        label: `Umowa sprzedaży koparki`
    }
];
