import React, { useState, useEffect } from "react";
import { Steps } from "antd";

const ProgressBarTransactionMode5 = (props) => {
  const [currentStage, setCurrentStage] = useState(0);

  useEffect(() => {
    if (props.transaction) {
      if (
        props.transaction.agreement_accepted_by_counterparty === true &&
        props.transaction.agreement_accepted_by_initiator === true
      ) {
        setCurrentStage(1);

        if (props.transaction.escrow_received) {
          setCurrentStage(2);

          if (props.transaction.items_sent) {
            setCurrentStage(3);

            if (props.transaction.items_received) {
              setCurrentStage(4);

              if (
                props.transaction.inspection_completed_successfully === false
              ) {
                setCurrentStage(5);

                if (props.transaction.inspection_items_refused_sent) {
                  setCurrentStage(6);

                  if (props.transaction.inspection_items_refused_received) {
                    setCurrentStage(7);

                    if (
                      props.transaction
                        .inspection_items_refused_received_inspection_completed_success
                    ) {
                      setCurrentStage(8);

                      if (props.transaction.finished) {
                        setCurrentStage(9);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [props.transaction]);

  const steps = [
    {
      title: "Umowa",
      description: "Uzgodnienie i akceptacja warunków",
    },
    {
      title: "Depozyt",
      description: "Zabezpieczenie środków na transakcję",
    },
    {
      title: "Dostawa",
      description: "Dostarczenie towaru lub usługi",
    },
    {
      title: "Odbiór",
      description: "Potwierdzenie odbioru",
    },
    {
      title: "Inspekcja",
      description: "Przedmiot niezgodny z umową",
      status: "error",
    },
    {
      title: "Zwrot",
      description: "Zwrot towaru lub usługi",
    },
    {
      title: "Odbiór",
      description: "Potwierdzenie odbioru",
    },    
    {
      title: "Inspekcja",
      description: "Sprawdzenie stanu zwrotu",
    },
    {
      title: "Zakończenie transakcji",
      description: "Zwrot środków",
    },
  ];

  if (
    props.transaction !== null &&
    props.transaction.transaction_mode_id !== 5
  ) {
    return <></>;
  }

  if (props.transaction !== null) {
    return <Steps size="small" current={currentStage} items={steps} />;
  }
};

export default ProgressBarTransactionMode5;
