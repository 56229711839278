import { combineReducers } from 'redux'

import transactionDataChanged from './features/transaction/transactionDataChanged';
import bankAccountsListUpdated from './features/bank/bankAccountsListUpdated';
import transactionCreated from './features/transaction/transactionCreated'
import accountDataChanged from './features/account/accountDataChanged';

const rootReducer = combineReducers({
    transactionDataChanged: transactionDataChanged,
    bankAccountsListUpdated: bankAccountsListUpdated,
    transactionCreated: transactionCreated,
    accountDataChanged: accountDataChanged
})

export default rootReducer;