import React, { useState, useEffect } from "react";
import { Space, Button, Modal, Input, message, Spin } from "antd";
import { isLogged, getAuthToken } from "../../../../../helpers/account";
import { api } from "../../../../../config/api";
import { useDispatch } from "react-redux";
import { isIBANValid } from "../../../../../helpers/validators";

const NewBankForm = () => {
  const [newBankModalOpen, setNewBankModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "", // Separate state variable for bank name
    number: "", // Separate state variable for account number
  });
  const dispatch = useDispatch();
  const handleCreateNewBank = () => {
    if (validateFormData()) {
      setLoading(true);
      // Sending data using API
      const apiData = {
        name: formData.name,
        number: formData.number,
      };

      const apiUrl = `${api.host}/bank`;

      if (isLogged()) {
        // Fetch bank account data from the API
        fetch(apiUrl, {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),
          body: JSON.stringify(apiData), // Send the data in the request body
        })
          .then((response) => response.json())
          .then((data) => {
            setLoading(false);
            dispatch({
              type: "bankAccountsListUpdated",
              payload: {
                bankAccountsListUpdated: new Date().getSeconds(),
              },
            });
            message.success("Nowe konto zostało dodane.");
            setNewBankModalOpen(false);
            setFormData({
              name: "", // Separate state variable for bank name
              number: "", // Separate state variable for account number
            });
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error while creating bank account data:", error);
          });
      }
    }
  };

  const validateFormData = () => {
    // Data validation
    if (!formData.name || !formData.number) {
      message.error("Wszystkie pola są wymagane.");
      return false;
    }

    if (!isIBANValid(formData.number)) {
      message.error("Numer konta nie jest w prawidłowym formacie IBAN.");
      return false;
    }

    return true;
  };

  const handleNewBank = () => {
    setNewBankModalOpen(true);
  };

  const handleCancelNewBank = () => {
    setNewBankModalOpen(false);
  };

  return (
    <>
      <Spin spinning={loading}>
        <Button onClick={handleNewBank} type="primary">
          Nowe konto
        </Button>

        <Modal
          title="Nowe konto bankowe"
          open={newBankModalOpen}
          onOk={handleCreateNewBank}
          onCancel={handleCancelNewBank}
          okText="Dodaj"
          cancelText="Anuluj"
        >
          <Space size={15} direction="vertical" style={{ width: "100%" }}>
            <Input
              placeholder="Nazwa banku np. XYZ Bank"
              onChange={
                (e) => setFormData({ ...formData, name: e.target.value }) // Use separate state variable
              }
              value={formData.name} // Use separate state variable
            />

            <Input
              placeholder="Numer konta w formacie IBAN"
              onChange={
                (e) => setFormData({ ...formData, number: e.target.value }) // Use separate state variable
              }
              value={formData.number} // Use separate state variable
            />
          </Space>
        </Modal>
      </Spin>
    </>
  );
};

export default NewBankForm;
