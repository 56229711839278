import { Button, Modal } from "antd";
import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const FeaturesPage = () => {
  const [visible, setVisible] = useState(null);

  return (
    <>
      {" "}
      <Helmet>
        <title>Postpay - kluczowe funkcje</title>
      </Helmet>
      <div className="hero-background" style={{ marginTop: "-120px" }}>
        <div className="hero-background-5" style={{ paddingTop: "100px" }}>
          Umowa kupna - sprzedaży w mniej niż 10 minut, zarządzanie transakcjami
          uproszczone do granic możliwości, powiadomienia na każdym etapie.
        </div>
      </div>
      <div className="abouttxt">
        <h3>Bezproblemowe Zarządzanie Umowami</h3>
        <p>
          Platforma Postpay upraszcza proces zarządzania umowami, zapewniając
          przejrzystość i łatwość dla obu stron zaangażowanych w transakcję. Oto
          jak nasz system ułatwia płynne realizowanie umów:
        </p>

        <h3>Szybkie Potwierdzenie Umowy</h3>
        <p>
          Jak tylko umowa zostanie utworzona, zarówno nabywca (Nabywca), jak i
          sprzedawca (Sprzedawca) są proszeni o przejrzenie i zaakceptowanie
          warunków. Platforma podkreśla wszelkie oczekujące działania,
          zapewniając, że obie strony są w pełni świadome swoich obowiązków i
          aktualnego statusu umowy.
        </p>

        <h3>Powiadomienia w Czasie Rzeczywistym</h3>
        <p>
          Użytkownicy są natychmiast powiadamiani, gdy umowa wymaga ich
          akceptacji. Ta funkcja minimalizuje opóźnienia i utrzymuje proces
          transakcji w ruchu.
        </p>

        <h3>Wyraźna Identyfikacja Ról</h3>
        <p>
          Każda rola jest wyraźnie oznaczona. Na przykład, Andrzej Bernat jest
          oznaczony jako nabywca, a Adam Kowalski jako sprzedawca. Ta
          przejrzystość pomaga uniknąć nieporozumień co do obowiązków i
          oczekiwań każdej ze stron.
        </p>

        <h3>Status Umowy</h3>
        <p>
          Aktualny status umowy jest wyświetlany w widocznym miejscu.
          Użytkownicy mogą zobaczyć, czy umowa oczekuje na ich akceptację, czy
          została już zaakceptowana przez drugą stronę.
        </p>
      </div>
      <div className="howOtherUse">
        <h3>Co w Postpay najbardziej lubią użytkownicy?</h3>
        <div className="summary">
          Wspólnym mianownikiem wszystkich transakcji przez Postpay jest
          minimalizowane ryzyka utraty środków. Dla małych firm taka utrata to
          czasami być albo nie być, a dla Kowalskiego długie miesiące “odkuwana”
          się.
        </div>
        <div className="howOtherUse_personas">
          <div className="howOtherUse_persona_1">
            <p className="howOtherUse_nick">
              <img src="/img/svg/10.svg" alt="" /> Joanna
            </p>
            <p>
              Tworzę umowę, wysyłam do akceptacji i działamy. Szybki i wygodny
              proces.
            </p>
          </div>
          <div className="howOtherUse_persona_2">
            <p className="howOtherUse_nick">
              <img src="/img/svg/11.svg" alt="" /> Marcin
            </p>
            <p>
              Czytelne rozliczenia pomiędzy kontrahentami, to lubię najbardziej.
            </p>
          </div>
        </div>
        <div className="howOtherUse_personas">
          <div className="howOtherUse_persona_3">
            <p className="howOtherUse_nick">
              <img src="/img/svg/12.svg" alt="" /> Nikodem
            </p>
            <p>
              Wizualizacja etapów transakcji z aktualnym stanem i
              powiadomieniami.
            </p>
          </div>
          <div className="howOtherUse_persona_3">
            <p className="howOtherUse_nick">
              <img src="/img/svg/13.svg" alt="" /> Pola
            </p>
            <p>
              Mogę tworzyć wiele transakcji jednocześnie. Zlecam dużo zadań, to
              dla mnie ważne.
            </p>
          </div>
        </div>
      </div>
      <div className="abouttxt">
        <h3>Szczegółowe Informacje o Umowie</h3>
        <p>
          Wszystkie istotne szczegóły dotyczące umowy są łatwo dostępne.
          Obejmuje to datę zawarcia umowy, harmonogram dostawy oraz okres
          inspekcji, zapewniając obu stronom wszystkie niezbędne informacje pod
          ręką.
        </p>

        <h3>Przyjazny Interfejs Użytkownika</h3>
        <p>
          Interfejs platformy jest zaprojektowany w sposób intuicyjny i
          przyjazny dla użytkownika. Przycisk "Akceptuję" i "Odrzucam" są
          wyraźnie widoczne, co ułatwia użytkownikom podjęcie działania.
        </p>

        <h3>Przejrzystość Transakcji</h3>
        <p>
          Postpay zapewnia, że każda zmiana warunków umowy wymaga ponownej
          akceptacji obu stron. Ta funkcja utrzymuje przejrzystość i wzajemne
          porozumienie w całym procesie transakcji.
        </p>

        <p>
          Dzięki Postpay zarządzanie umowami jest prostsze niż kiedykolwiek.
          Nasza platforma zapewnia, że zarówno nabywcy, jak i sprzedawcy są
          zawsze na bieżąco, wspierając zaufanie i efektywność w każdej
          transakcji.
        </p>
        <br />
        <Link to="/account/register">
          <Button type="primary">ROZPOCZNIJ NOWĄ TRANSAKCJĘ</Button>
        </Link>
      </div>
      <div className="ceo">
        <p>
          {" "}
          W odpowiedzi na rosnący problem transakcji oszukańczych, stworzyliśmy
          usługę Postpay – parasol ochronny dla Twoich transakcji w Internecie.
        </p>
        <p>
          <strong>
            Minimalizujemy ryzyko chroniąc Twoje pieniądze przed oszustwami.
            Wybierz Postpay i ciesz się spokojem podczas każdej transakcji
            online.
          </strong>
        </p>
        <div className="photo">
          <img src="/img/svg/3.svg" />
        </div>
        <div className="sign">
          <img src="/img/svg/4.svg" />
        </div>
        <div className="postpay">Postpay CEO</div>
      </div>
      <div className="partners">
        <img src="/img/svg/14.svg" />
      </div>
    </>
  );
};

export default FeaturesPage;
