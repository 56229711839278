import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import LoginPage from "../../../Login/LoginPage";
import { Switch, Card, Space, Row, Col, Breadcrumb, Spin, message } from "antd";
import { isLogged, getAuthToken } from "../../../../helpers/account";
import { api } from "../../../../config/api";
import { UnorderedListOutlined, HomeOutlined, ShoppingOutlined } from "@ant-design/icons";
import { formatPolishCurrency } from "../../../../helpers/currency";

function AccountProductPage() {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [accountProductLoading, setAccountProductLoading] = useState(false);
    const [isOffered, setIsOffered] = useState(false);
    const [accountProductUUID, setAccountProductUUID] = useState(null);

    const { productUuid } = useParams();

    const handleAccountProductState = async (state) => {
        try {
            if (isLogged()) {
             
                setAccountProductLoading(true);
                if (state) {
                    // If the product is offered, create a record in the database
                    await fetch(`${api.host}/accounts/products`, {
                        method: "POST",
                        headers: new Headers({
                            "Content-Type": "application/json",
                            Authorization: `${getAuthToken()}`,
                        }),
                        body: JSON.stringify({
                            uuid: productUuid
                        })
                    });
                    message.success("Zmiany zostały zapisane.");
                } else {
                    // If the product is not offered, delete the record from the database
                    await fetch(`${api.host}/accounts/products/${accountProductUUID}`, {
                        method: "DELETE",
                        headers: new Headers({
                            "Content-Type": "application/json",
                            Authorization: `${getAuthToken()}`,
                        }),
                    });
                    message.success("Zmiany zostały zapisane.");
                }
                fetchData();
            
            }
        } catch (error) {
            console.error("Error updating product state:", error);
        }
    }

    const fetchData = async () => {
        try {
            if (isLogged()) {
                setAccountProductLoading(true);
                const productResponse = await fetch(`${api.host}/products/${productUuid}`, {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        Authorization: `${getAuthToken()}`,
                    }),
                });
                const productData = await productResponse.json();
                setData(productData);

                const assignedProductsResponse = await fetch(`${api.host}/accounts/products`, {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        Authorization: `${getAuthToken()}`,
                    }),
                });
                const assignedProductsData = await assignedProductsResponse.json();

                const assignedProduct = assignedProductsData.find(accountProduct => accountProduct.product.uuid === productUuid);
                if (assignedProduct) {
                    setIsOffered(true);
                    setAccountProductUUID(assignedProduct.uuid);
                } else {
                    setIsOffered(false);
                    setAccountProductUUID(null);
                }
                // Set loading to false after fetching the data
                setAccountProductLoading(false);
            }
        } catch (error) {
            console.error("Error fetching product data:", error);
            setAccountProductLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [productUuid]);

    if (isLogged()) {
        return (
            <>
                <Row
                    style={{
                        minHeight: "100vh",
                    }}
                >
                    <Col
                        span={24}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Space size={15} direction="vertical">
                            <Breadcrumb
                                items={[
                                    {
                                        href: "/transactions",
                                        title: <HomeOutlined />,
                                    },
                                    {
                                        href: "/account/offer",
                                        title: (
                                            <>
                                                <UnorderedListOutlined />
                                                <span>Moja oferta</span>
                                            </>
                                        ),
                                    },
                                    {
                                        title: "Produkty",
                                    },
                                ]}
                            />

                            <Card
                                title={
                                    <>
                                        <ShoppingOutlined /> {data.name}
                                    </>
                                }
                            >
                                <Space direction="vertical" size={30}>

                                    <p dangerouslySetInnerHTML={{ __html: data.description ? data.description.replace(/\n/g, "<br>") : '' }}></p>
                                    <p>{formatPolishCurrency(data.price)}</p>

                                    <Spin spinning={accountProductLoading}>
                                        <Card>
                                            <Space direction="vertical">
                                                <Space>
                                                    <Switch checked={isOffered} onChange={handleAccountProductState} />
                                                    <p>
                                                        Oferuj ten produkt
                                                    </p>
                                                </Space>
                                            </Space>
                                        </Card>
                                    </Spin>

                                </Space>
                            </Card>
                        </Space>
                    </Col>
                </Row>
            </>
        );
    } else {
        return <LoginPage />;
    }
}

export default AccountProductPage;
