import React from "react";
import { Card, Avatar, Space, Row, Col } from "antd";
const RegulationsPage = () => {
  return (
    <Row
      style={{
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Col md={12} sm={20}>
        <h1 className="headline offer">
          Regulamin Usługi Depozytowej Postpay.pl
        </h1>
        <Space direction="vertical">
          <h2>Rozdział 1: Postanowienia Ogólne</h2>

          <h3>§ 1. Definicje</h3>
          <p>
            1. <strong>Usługa Depozytowa</strong>: Usługa typu escrow oferowana
            przez Postpay.pl, prowadzoną przez firmę SZOPS Monika Płotek, z
            siedzibą w Choszcznie, ul. Fabryczna 5, 73-200 Choszczno, NIP:
            594-153-38-20, REGON: 320305252, prowadzącą działalność pod nazwą
            Postpay.pl, będącą bezpośrednim dostawcą usług depozytowych.
          </p>
          <p>
            2. <strong>Użytkownik</strong>: Osoba korzystająca z usługi
            depozytowej Postpay.pl w celu zabezpieczenia transakcji.
          </p>
          <p>
            3. <strong>Transakcja</strong>: Umowa lub operacja, do której odnosi
            się Usługa Depozytowa.
          </p>
          <p>
            4. <strong>Towary i Usługi</strong>: Przedmioty transakcji, za które
            odpowiadają strony transakcji.
          </p>
          <p>
            5. <strong>Platforma</strong>: Oprogramowanie i systemy używane do
            świadczenia Usługi Depozytowej.
          </p>

          <h3>§ 2. Warunki Ogólne</h3>
          <p>
            1. Usługa Depozytowa dostępna jest wyłącznie dla pełnoletnich osób.
          </p>
          <p>
            2. Postpay.pl dokłada wszelkich starań, aby oprogramowanie platformy
            było niezawodne, ale nie odpowiada za opóźnienia transakcji
            wynikające z potencjalnych awarii platformy.
          </p>
          <p>
            3. W przypadku opóźnień transakcji spowodowanych awarią platformy,
            strony transakcji muszą komunikować się bezpośrednio w celu
            rozwiązania ewentualnych problemów.
          </p>
          <p>
            4. Usługa Depozytowa dostępna jest na terytorium Polski, z wyjątkiem
            innych jurysdykcji, w których dostępność usługi może być ograniczona
            przez obowiązujące przepisy.
          </p>
          <p>
            5. Wpłata depozytu odbywa się na specjalne, nieoprocentowane konto
            Postpay, a wielkość depozytu określa umowa zawarta między stronami
            transakcji.
          </p>
          <p>
            6. Opłaty za Usługę Depozytową zostaną pobrane zarówno w przypadku
            udanego zakończenia transakcji, jak i w przypadku transakcji, które
            nie doszły do skutku lub zakończyły się konfliktem. Wypłata depozytu
            może być pomniejszona o opłaty i prowizje PostPay.
          </p>

          <h2>Rozdział 2: Zakładanie Konta</h2>

          <h3>§ 3. Rejestracja Konta</h3>
          <p>
            1. Aby skorzystać z Usługi Depozytowej, Użytkownik musi
            zarejestrować konto na stronie Postpay.pl.
          </p>
          <p>
            2. Podczas rejestracji Użytkownik zobowiązuje się do podania
            prawdziwych i aktualnych informacji.
          </p>

          <h3>§ 4. Dokumenty Weryfikacyjne</h3>
          <p>
            1. Postpay.pl może zażądać od Użytkownika dostarczenia odpowiednich
            dokumentów w celu weryfikacji jego tożsamości.
          </p>
          <p>
            2. W przypadku braku dostarczenia wymaganych dokumentów, Postpay.pl
            zastrzega sobie prawo do zawieszenia lub zablokowania konta
            Użytkownika.
          </p>

          <h2>Rozdział 3: Depozyt Środków</h2>

          <h3>§ 5. Wpłaty Depozytu</h3>
          <p>
            1. Wpłata depozytu odbywa się na specjalne, nieoprocentowane konto
            Postpay.
          </p>
          <p>
            2. Wielkość depozytu określa umowa zawarta między stronami
            transakcji.
          </p>
          <p>3. Minimalna kwota depozytu wynosi [określić minimalną kwotę].</p>

          <h3>§ 6. Opłaty i Prowizje</h3>
          <p>
            1. Postpay.pl może pobierać opłaty i prowizje za korzystanie z
            Usługi Depozytowej. Informacje na ten temat są dostępne na stronie
            internetowej Postpay.pl.
          </p>
          <p>
            2. Postpay.pl zastrzega sobie prawo do zmiany opłat i prowizji w
            dowolnym czasie, z zachowaniem wymaganego okresu powiadomienia.
          </p>

          <h2>Rozdział 4: Korzystanie z Depozytu</h2>

          <h3>§ 7. Dostęp do Depozytu</h3>
          <p>
            1. Użytkownik ma dostęp do swojego depozytu za pomocą panelu
            użytkownika na stronie Postpay.pl.
          </p>
          <p>
            2. Użytkownik jest odpowiedzialny za zachowanie poufności swojego
            hasła dostępu.
          </p>

          <h3>§ 8. Wypłaty i Transakcje</h3>
          <p>
            1. Użytkownik może żądać wypłaty środków z depozytu zgodnie z
            procedurami dostępnych na stronie Postpay.pl.
          </p>
          <p>
            2. Wszelkie transakcje związane z depozytem będą realizowane zgodnie
            z instrukcjami stron transakcji. Postpay.pl pełni rolę
            depozytariusza w ramach tych transakcji i nie bierze
            odpowiedzialności za jakość, stan, zgodność z opisem towarów i usług
            oraz sposoby i terminy dostaw tych towarów i usług. Wypłata depozytu
            może być pomniejszona o opłaty i prowizje PostPay.
          </p>
          <p>
            3. Użytkownik jest świadomy i zgadza się, że korzystanie z Usługi
            Depozytowej Postpay.pl obejmuje respektowanie obowiązujących
            przepisów prawa polskiego. Zabrania się przeprowadzania transakcji,
            które są zabronione lub naruszają polskie prawo. To zawiera, lecz
            nie ogranicza się do, transakcji dotyczących towarów i usług, które
            są nielegalne, niezgodne z regulacjami lub naruszają prawa własności
            intelektualnej. Użytkownik bierze pełną odpowiedzialność za
            przestrzeganie tych przepisów i regulacji. Postpay.pl nie bierze
            odpowiedzialności za decyzje podejmowane przez użytkownika ani za
            ewentualne szkody wyrządzone podczas transakcji, które naruszają
            polskie prawo.
          </p>

          <h2>Rozdział 5: Zakończenie Usługi</h2>

          <h3>§ 9. Zamknięcie Konta</h3>
          <p>1. Użytkownik ma prawo zamknąć swoje konto w dowolnym czasie.</p>
          <p>
            2. Postpay.pl zastrzega sobie prawo do zamknięcia konta Użytkownika
            w przypadku naruszenia regulaminu lub podejrzenia oszustwa.
          </p>

          <h2>Rozdział 6: Postanowienia Końcowe</h2>

          <h3>§ 10. Postanowienia Ogólne</h3>
          <p>
            1. Regulamin obowiązuje od 20 października 2023 i jest dostępny na
            stronie Postpay.pl.
          </p>
          <p>
            2. Wszelkie spory wynikłe z korzystania z Usługi Depozytowej będą
            rozpatrywane przez sąd właściwy na terytorium Polski.
          </p>
          <p>
            3. Wszelkie zmiany w regulaminie będą ogłaszane na stronie
            Postpay.pl.
          </p>

          <p>
            Zapoznaj się z tym regulaminem dokładnie przed korzystaniem z Usługi
            Depozytowej Postpay.pl. Jeśli masz pytania lub potrzebujesz dalszej
            pomocy, zawsze możesz skontaktować się z nami.
          </p>
        </Space>
      </Col>
    </Row>
  );
};

export default RegulationsPage;
