import React, { useState } from "react";
import { Alert, Button, Space } from "antd";
import { formatPolishCurrency } from "../../../helpers/currency";
import { getLoggedAccountTransactionRoleId } from "../../../helpers/account";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { getAuthToken } from "../../../helpers/account";
import { api } from "../../../config/api";

import CheckoutForm from "../Payment/CheckoutForm";

const stripePromise = loadStripe(
  "pk_live_51PFiA9P9o5jlolOrdoEQa8hSc5MKcwy9pRt2ae5zBnFD05yRr1i5VBijWKuPgOSc7B14EV6tXNyxwXWdJCrOwD1e00rmYzOloq"
);

const LoadEscrowAction = (props) => {
  const [open, setOpen] = useState(false);
  const [clientSecret, setClientSecret] = useState("");

  const handlePaymentIntent = () => {
    fetch(`${api.host}/payments/intent/${props.transaction.uuid}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  };

  let canBeRendered = false;

  if (
    props.transaction &&
    getLoggedAccountTransactionRoleId(props.transaction) === 2 &&
    props.transaction.agreement_accepted_by_initiator === true &&
    props.transaction.agreement_accepted_by_counterparty === true &&
    props.transaction.escrow_received === false
  ) {
    canBeRendered = true;
  }

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  if (canBeRendered === true) {
    return (
      <>
       <Space direction="vertical">
 
        <Alert
          type="warning"
          message={
            <>
              Wpłać depozyt w wysokości{" "}
              {formatPolishCurrency(props.transaction.escrow_amount_total)}
            </>
          }
          description="Proszę wpłacić depozyt, aby kontynuować transakcję. Szczegóły rozliczeń dostępne w zakładce Rozliczenia."
          action={
            <Space direction="vertical">
              <Button type="primary" onClick={handlePaymentIntent}>
                POKAŻ OPCJE
              </Button>
            </Space>
          }
        />
              {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        )}
       </Space>
      </>
    );
  }
};

export default LoadEscrowAction;
