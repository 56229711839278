import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import LoginPage from "../../../Login/LoginPage";
import { Table, Card, Space, Row, Col, Breadcrumb } from "antd";
import { useSelector } from "react-redux";
import { isLogged, getAuthToken } from "../../../../helpers/account";
import { api } from "../../../../config/api";
import { UnorderedListOutlined, HomeOutlined, ShoppingOutlined } from "@ant-design/icons";

const columns = [
    {
        title: "Nazwa",
        dataIndex: "name",
        key: "name",
        fixed: "left",
        sorter: true,
        render: (name, record) => (
            <>
                <Link to={`/account/offer/product/${record.uuid}`}>{name}</Link>
            </>
        ),
    },
];

function AccountProductsCategoriesPage() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const bankAccountsListUpdated = useSelector(
        (state) => state.bankAccountsListUpdated
    );
    const { categoryUuid } = useParams();

    useEffect(() => {
        const apiUrl = `${api.host}/products/category/${categoryUuid}`;

        if (isLogged()) {
            // Fetch bank account data from the API
            fetch(apiUrl, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `${getAuthToken()}`,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    setData(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching categories data:", error);
                    setLoading(false);
                });
        }
    }, [bankAccountsListUpdated]);

    if (isLogged()) {
        return (
            <>
                <Row
                    style={{
                        minHeight: "100vh",
                    }}
                >
                    <Col
                        span={24}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Space size={15} direction="vertical">
                            <Breadcrumb
                                items={[
                                    {
                                        href: "/transactions",
                                        title: <HomeOutlined />,
                                    },
                                    {
                                        href: "/account/offer",
                                        title: (
                                            <>
                                                <UnorderedListOutlined />
                                                <span>Moja oferta</span>
                                            </>
                                        ),
                                    },
                                    {
                                        title: "Produkty",
                                    },
                                ]}
                            />

                            <Card
                                title={
                                    <>
                                        <ShoppingOutlined /> Produkty & Usługi
                                    </>
                                }
                            >
                                <Space direction="vertical" size={30}>

                                    <Table
                                        pagination={{ pageSize: 5 }}
                                        className="shadow"
                                        columns={columns}
                                        dataSource={data}
                                        loading={loading}
                                        locale={{
                                            emptyText: "Brak produktów w kategorii",
                                        }}
                                    />
                                </Space>
                            </Card>
                        </Space>
                    </Col>
                </Row>
            </>
        );
    } else {
        return <LoginPage />;
    }
}

export default AccountProductsCategoriesPage;
