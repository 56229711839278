import React, { useState } from "react";
import { Card, Input, Space, Button, Row, Col, message, Spin } from "antd";
import {
  DollarOutlined,
  UserOutlined,
  BlockOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { api } from "../../config/api";
import { Link } from "react-router-dom";
import { destroyAuthToken } from "../../helpers/account";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [emailConfirmed, setEmailConfirmed] = useState(true);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const handleLogin = () => {
    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/accounts/login`;
    setLoading(true);
    // Define your request body (you may need to adjust this based on your API)
    const requestBody = {
      email,
      password,
      code,
    };

    fetch(apiUrl, {
      method: "POST", // Use 'POST' if your API requires it
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          // Parse the JSON response body
          response
            .json()
            .then((data) => {
              if (data.authToken) {
                messageApi.open({
                  type: "success",
                  content: "Dane poprawne, jesteś zalogowany",
                });
                // Save authToken in session storage
                sessionStorage.setItem("authToken", data.authToken);
                sessionStorage.setItem("uuid", data.uuid);
                setTimeout(() => {
                  window.location.href = "/transactions";
                }, 2000);
              } else {
                setEmailConfirmed(false);
                messageApi.open({
                  type: "error",
                  content: "Adres e-mail nie został potwierdzony",
                });
              }
            })
            .catch((error) => {
              messageApi.open({
                type: "error",
                content: "Wystąpił błąd w akcji logowania",
              });
            });
        } else {
          setLoading(false);
          messageApi.open({
            type: "warning",
            content: "Błędne dane logowania",
          });
          destroyAuthToken();
        }
      })
      .catch((error) => {
        setLoading(false);
        messageApi.open({
          type: "warning",
          content: "Błędne dane logowania",
        });
        destroyAuthToken();
      });
  };

  return (
    <Row
      style={{
        height: 500,

        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      {contextHolder}
      <Col
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <Spin spinning={loading}>
          <Card
            title={
              <>
                <UserOutlined /> Mój Postpay
              </>
            }
            extra={
              <Link to="/">
                <CloseCircleOutlined style={{ color: "#8c8c8c" }} />
              </Link>
            }
            style={{
              width: 350,
              boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
            }}
          >
            <Space size={30} direction="vertical">
              <Input
                size="large"
                autoComplete="off"
                placeholder="Adres e-mail"
                prefix={<UserOutlined />}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <Input.Password
                size="large"
                autoComplete="off"
                placeholder="Hasło"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              {emailConfirmed === false ? (
                <>
                  {" "}
                  <p>
                    Przepisz otrzymany kod weryfikacyjny, który wysłaliśmy na
                    podany adres e-mail.
                  </p>
                  <Input
                    size="large"
                    autoComplete="off"
                    placeholder="Kod weryfikacyjny"
                    prefix={<BlockOutlined />}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </>
              ) : (
                <></>
              )}

              <Button type="primary" onClick={handleLogin}>
                Zaloguj
              </Button>

              <p style={{ marginTop: 0 }}>
                <Link to="/account/password-reset">Zmień hasło</Link> jeśli
                zapomniałeś.{" "}
                <Link to="/account/register">Załóż darmowe konto</Link> jeśli
                jeszcze nie posiadasz.
              </p>
            </Space>
          </Card>
        </Spin>
      </Col>
    </Row>
  );
};

export default LoginPage;
