import React, { useState, useEffect } from "react";
import { Space, Button, Modal, Input, message, Spin } from "antd";
import { isLogged, getAuthToken } from "../../../../../helpers/account";
import { api } from "../../../../../config/api";
import { useDispatch } from "react-redux";
import { isIBANValid } from "../../../../../helpers/validators";

const EditBankForm = (props) => {
  const [editBankModalOpen, setEditBankModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "", // Separate state variable for bank name
    number: "", // Separate state variable for account number
  });
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch bank data from the API when the component is mounted
    if (editBankModalOpen && props.bank && props.bank.uuid) {
      const apiUrl = `${api.host}/bank/${props.bank.uuid}`;

      if (isLogged()) {
        fetch(apiUrl, {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            // Populate the form fields with fetched data
            setFormData({
              name: data.name,
              number: data.number,
            });
          })
          .catch((error) => {
            console.error("Error fetching bank account data:", error);
          });
      }
    }
  }, [editBankModalOpen]);

  const handleEditBank = () => {
    if (validateFormData()) {
      setLoading(true);
      // Sending data using API
      const apiData = {
        name: formData.name,
        number: formData.number,
      };

      const apiUrl = `${api.host}/bank/${props.bank.uuid}`;

      if (isLogged()) {
        // Fetch bank account data from the API
        fetch(apiUrl, {
          method: "PUT",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),
          body: JSON.stringify(apiData), // Send the data in the request body
        })
          .then((response) => response.json())
          .then((data) => {
            setLoading(false);
            dispatch({
              type: "bankAccountsListUpdated",
              payload: {
                bankAccountsListUpdated: new Date().getSeconds(),
              },
            });
            message.success("Zmiany zostały zapisane.");
            setEditBankModalOpen(false);
            setFormData({
              name: "", // Separate state variable for bank name
              number: "", // Separate state variable for account number
            });
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error while updating bank account data:", error);
          });
      }
    }
  };

  const handleDeleteBank = () => {
    if (validateFormData()) {
      setLoading(true);
      // Sending data using API
      const apiData = {
        name: formData.name,
        number: formData.number,
      };

      const apiUrl = `${api.host}/bank/${props.bank.uuid}`;

      if (isLogged()) {
        // Fetch bank account data from the API
        fetch(apiUrl, {
          method: "DELETE",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),
          body: JSON.stringify(apiData), // Send the data in the request body
        })
          .then((response) => response.json())
          .then((data) => {
            setLoading(false);
            dispatch({
              type: "bankAccountsListUpdated",
              payload: {
                bankAccountsListUpdated: new Date().getSeconds(),
              },
            });
            message.success("Konto bankowe zostało usunięte.");
            setEditBankModalOpen(false);
            setFormData({
              name: "", // Separate state variable for bank name
              number: "", // Separate state variable for account number
            });
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error while deleting bank account data:", error);
          });
      }
    }
  };

  const validateFormData = () => {
    // Data validation
    if (!formData.name || !formData.number) {
      message.error("Wszystkie pola są wymagane.");
      return false;
    }

    if (!isIBANValid(formData.number)) {
      message.error("Numer konta nie jest w prawidłowym formacie IBAN.");
      return false;
    }

    return true;
  };

  const handleEditBankForm = () => {
    setEditBankModalOpen(true);
  };

  const handleCancelEditBank = () => {
    setEditBankModalOpen(false);
  };

  return (
    <>
      <Spin spinning={loading}>
        <Button size="small" onClick={handleEditBankForm} type="default">
          Edytuj
        </Button>

        <Modal
          title="Edycja konta bankowego"
          open={editBankModalOpen}
          footer={[
            <Space>
              <Space size={30}>
                <>
                  <Button key="delete" onClick={handleDeleteBank}>
                    Usuń
                  </Button>

                  <Button key="back" onClick={handleCancelEditBank}>
                    Anuluj
                  </Button>
                </>
              </Space>
              <Button key="submit" type="primary" onClick={handleEditBank}>
                Zapisz zmiany
              </Button>
            </Space>,
          ]}
        >
          <Space size={15} direction="vertical" style={{ width: "100%" }}>
            <Input
              placeholder="Nazwa banku np. XYZ Bank"
              onChange={
                (e) => setFormData({ ...formData, name: e.target.value }) // Use separate state variable
              }
              value={formData.name} // Use separate state variable
            />

            <Input
              placeholder="Numer konta w formacie IBAN"
              onChange={
                (e) => setFormData({ ...formData, number: e.target.value }) // Use separate state variable
              }
              value={formData.number} // Use separate state variable
            />
          </Space>
        </Modal>
      </Spin>
    </>
  );
};

export default EditBankForm;
