import React, { useState, useEffect } from "react";
import { Routes, Link, Route } from 'react-router-dom';
import LoginPage from "../../../Login/LoginPage";
import { Table, Card, Space, Row, Col, Breadcrumb } from "antd";
import { useSelector } from "react-redux";
import { isLogged, getAuthToken } from "../../../../helpers/account";
import { api } from "../../../../config/api";
import { UnorderedListOutlined, HomeOutlined, ShoppingOutlined } from "@ant-design/icons";


const categoryColumns = [
    {
        title: "Nazwa",
        dataIndex: "name",
        key: "name",
        fixed: "left",
        sorter: true,
        render: (name, record) => (
            <>
                <Link to={`/account/offer/category/${record.uuid}`}>{name}</Link>
            </>
        ),
    },
];

const accountProductColumns = [
    {
        title: "Nazwa",
        dataIndex: "name",
        key: "name",
        fixed: "left",
        sorter: true,
        render: (name, record) => (
            <>
                <Link to={`/account/offer/product/${record.product.uuid}`}>{record.product.name}</Link>
            </>
        ),
    },
];

function AccountCategoriesPage() {
    const [data, setData] = useState([]);
    const [accountProducts, setAccountProducts] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {

        if (isLogged()) {
            fetch(`${api.host}/categories`, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `${getAuthToken()}`,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    setData(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching categories data:", error);
                    setLoading(false);
                });

            fetch(`${api.host}/accounts/products`, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `${getAuthToken()}`,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    setAccountProducts(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching account products data:", error);
                    setLoading(false);
                });
        }
    }, []);

    if (isLogged()) {
        return (
            <>
                <Row
                    style={{
                        minHeight: "100vh",
                    }}
                >
                    <Col
                        span={24}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >


                        <Space size={15} direction="vertical">

                            <Breadcrumb
                                items={[
                                    {
                                        href: "/transactions",
                                        title: <HomeOutlined />,
                                    },
                                    {
                                        title: "Moja oferta",
                                    },
                                ]}
                            />

                            <Card
                                title={
                                    <>
                                        <ShoppingOutlined /> Moja oferta
                                    </>
                                }
                            >
                                <Space direction="vertical" size={30}>

                                    <Table
                                        pagination={{ pageSize: 5 }}
                                        className="shadow"
                                        columns={accountProductColumns}
                                        dataSource={accountProducts}
                                        loading={loading}
                                        locale={{
                                            emptyText: "Nie oferujesz żadnych produktów i usług",
                                        }}
                                    />
                                </Space>
                            </Card>

                            <Card
                                title={
                                    <>
                                        <UnorderedListOutlined /> Kategorie usług
                                    </>
                                }
                            >
                                <Space direction="vertical" size={30}>

                                    <Table
                                        pagination={{ pageSize: 5 }}
                                        className="shadow"
                                        columns={categoryColumns}
                                        dataSource={data}
                                        loading={loading}
                                        locale={{
                                            emptyText: "Brak kategorii usług",
                                        }}
                                    />
                                </Space>
                            </Card>
                            <br/>
                        </Space>
                    </Col>
                </Row>
            </>
        );
    } else {
        return <LoginPage />;
    }
}

export default AccountCategoriesPage;
