import React, { useState, useEffect } from "react";
import {
  Space,
  Button,
  Modal,
  Input,
  message,
  Spin,
  Select,
  Steps,
} from "antd";
import { isLogged, getAuthToken } from "../../helpers/account";
import { api } from "../../config/api";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const { TextArea } = Input;

const NewItemForm = (props) => {
  const [newItemModalOpen, setNewItemModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categoryPricingData, setCategoryPricingData] = useState([]);
  const [loadingCategoryPricing, setLoadingCategoryPricing] = useState(true);
  const [formData, setFormData] = useState({
    name: "", // Separate state variable for bank name
    price: "", // Separate state variable for account number
    item_category_id: undefined,
  });
  const dispatch = useDispatch();
  const transactionCreated = useSelector((state) => state.transactionCreated);

  useEffect(() => {
    if (newItemModalOpen === true) {
      setLoading(true);
      // Your API endpoint URL goes here
      const apiUrl = `${api.host}/categories`; // Update with your API endpoint

      // Fetch data from the API
      fetch(apiUrl, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setCategoryPricingData(
            data.map((category) => ({
              value: category.id,
              label: category.name,
            }))
          ); // Update the state with the fetched data
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [newItemModalOpen]);

  const handleCreateNewItem = () => {
    if (validateFormData()) {
      setLoading(true);
      // Sending data using API
      const itemData = {
        name: formData.name,
        price: formData.price,
        item_category_id: 1,
      };

      const apiUrl = `${api.host}/transactions/add-item`;

      if (isLogged()) {
        // Fetch bank account data from the API
        fetch(apiUrl, {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),
          body: JSON.stringify({
            transactionUuid: props.transaction.uuid,
            itemData,
          }), // Send the data in the request body
        })
          .then((response) => response.json())
          .then((data) => {
            setLoading(false);
            dispatch({
              type: "transactionDataUpdated",
              payload: {
                transactionDataChanged: new Date().getSeconds(),
              },
            });
            message.success("Przedmiot został dodany do transakcji.");
            setNewItemModalOpen(false);
            setFormData({
              name: "", // Separate state variable for bank name
              price: "", // Separate state variable for account number
              item_category_id: "", // Separate state variable for account number
            });
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error while creating new item data:", error);
          });
      }
    }
  };

  const validateFormData = () => {
    // Data validation
    if (!formData.name || !formData.price) {
      message.error("Wszystkie pola są wymagane.");
      return false;
    }

    return true;
  };

  const handleNewItem = () => {
    setNewItemModalOpen(true);
  };

  const handleCancelNewItem = () => {
    setNewItemModalOpen(false);
  };

  useEffect(() => {
    if (transactionCreated === true) {
      setNewItemModalOpen(true);
    }
  }, [transactionCreated]);

  return (
    <>
      <Spin spinning={loading}>
        <Button
          disabled={
            props.transaction &&
            ((props.transaction.agreement_accepted_by_initiator &&
              props.transaction.agreement_accepted_by_counterparty) ||
              props.transaction.finished)
          }
          style={{ float: "right" }}
          onClick={handleNewItem}
          type="primary"
        >
          Nowy przedmiot
        </Button>

        <Modal
          title="Nowy przedmiot umowy"
          open={newItemModalOpen}
          onOk={handleCreateNewItem}
          onCancel={handleCancelNewItem}
          okText="Dodaj"
          cancelText="Anuluj"
        >
          <Space size={15} direction="vertical" style={{ width: "100%" }}>
            <Steps
              size="small"
              current={1}
              items={[
                {
                  title: "Utworzenie transakcji",
                },
                {
                  title: "Dodanie przedmiotów umowy",
                },
              ]}
            />

            <Input
              placeholder="Nazwa przedmiotu"
              onChange={
                (e) => setFormData({ ...formData, name: e.target.value }) // Use separate state variable
              }
              value={formData.name} // Use separate state variable
            />

            <Input
              style={{
                width: 150,
              }}
              placeholder="Wartość w zł"
              onChange={
                (e) => setFormData({ ...formData, price: e.target.value }) // Use separate state variable
              }
              value={formData.price} // Use separate state variable
            />
          </Space>
        </Modal>
      </Spin>
    </>
  );
};

export default NewItemForm;
