import React from "react";
import { Card, Collapse, Space, Button, Row, Col, Steps } from "antd";
const JobPage = () => {
  return (
    <Row
      style={{
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Col span={12}>
        <h1 className="headline offer">
          Jeśli jesteś zmotywowany do tworzenia nowoczesnych rozwiązań i rozwoju
          firmy z rosnącym wpływem na rynku dołącz do nas!
        </h1>
        <h2
          className="headline offer"
          style={{ fontSize: "275%", color: "#8c8c8c" }}
        ></h2>
        <Space size={30} direction="vertical">
          <h3>Oferty</h3>
          <Collapse
            style={{ textAlign: "left", width: "100%" }}
            defaultActiveKey={0}
            items={[
              {
                key: "1",
                label: "Fullstack Developer Node.js + React (zdalnie)",
                children: (
                  <p>
                    Jeśli dokonujesz transakcji o małej wartości, warto rozważyć
                    inne opcje płatności lub metody, które mogą być bardziej
                    odpowiednie dla takich sytuacji. PostPay, zapewniając
                    ochronę i bezpieczeństwo, został zoptymalizowany przede
                    wszystkim dla transakcji powyżej 50 zł, co oznacza, że jego
                    mechanizmy ochronne są najskuteczniejsze w przypadku
                    większych kwot. W przypadku mniejszych transakcji warto
                    rozważyć alternatywne środki płatności, które mogą być
                    bardziej efektywne i wygodne w takich sytuacjach.
                  </p>
                ),
              },
              {
                key: "2",
                label: "QA/Tester + React (zdalnie)",
                children: <p>...</p>,
              },
              {
                key: "3",
                label: "QA/Tester automatyzujący + Node.js (zdalnie)",
                children: <p>...</p>,
              },
              {
                key: "4",
                label:
                  "Senior Database Developer - PostgreSQL (zdalnie)",
                children: <p>...</p>,
              },
              ,
              {
                key: "5",
                label: "DevOps Engineer (zdalnie)",
                children: <p>...</p>,
              },
              {
                key: "6",
                label: "Opiekun klienta B2C/B2B (zdalnie)",
                children: <p>...</p>,
              },
            ]}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default JobPage;
