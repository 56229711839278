import React, { useState } from "react";
import { Alert, Button, Space, message } from "antd";
import { api } from "../../../config/api";
import { getAuthToken } from "../../../helpers/account";
import { isTransactionInitiatedByLoggedAccount } from "../../../helpers/account";
import { useDispatch } from "react-redux";
import {
  requiresInitiatorToAcceptAgreement,
  requiresCounterpartyToAcceptAgreement,
} from "../../../helpers/actions";

const AcceptAgreementAction = (props) => {
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();

  let canBeRendered = false;

  if (
    props.transaction &&
    (requiresInitiatorToAcceptAgreement(props.transaction) ||
      requiresCounterpartyToAcceptAgreement(props.transaction))
  ) {
    canBeRendered = true;
  }

  const handleAccept = () => {
    setLoading(true);

    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/transactions/${props.transaction.uuid}`;
    let jsonData = {};
    if (isTransactionInitiatedByLoggedAccount(props.transaction)) {
      jsonData = {
        agreement_accepted_by_initiator: true,
      };
    } else {
      jsonData = {
        agreement_accepted_by_counterparty: true,
      };
    }

    // Fetch data from the API
    fetch(apiUrl, {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      }),
      body: JSON.stringify(jsonData), // Convert JSON object to a string
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        messageApi.open({
          type: "success",
          content: "Umowa została zaakceptowana.",
        });
        setTimeout(() => {
          // Additional logic to be executed after a delay (2 seconds in this case)
          dispatch({
            type: "transactionDataUpdated",
            payload: {
              transactionDataChanged: new Date().getSeconds(),
            },
          });
          setLoading(false);
        }, 2000); // Delay for 2 seconds
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Wystąpiły błedy podczas zapisu umowy.",
        });
        setLoading(false); // Set loading to false even on error
      });
  };

  const handleReject = () => {
    setLoading(true);

    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/transactions/${props.transaction.uuid}`;
    let jsonData = {};
    if (isTransactionInitiatedByLoggedAccount(props.transaction)) {
      jsonData = {
        agreement_accepted_by_initiator: false,
      };
    } else {
      jsonData = {
        agreement_accepted_by_counterparty: false,
      };
    }

    // Fetch data from the API
    fetch(apiUrl, {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      }),
      body: JSON.stringify(jsonData), // Convert JSON object to a string
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        messageApi.open({
          type: "success",
          content: "Umowa została odrzucona.",
        });
        setTimeout(() => {
          // Additional logic to be executed after a delay (2 seconds in this case)
          dispatch({
            type: "transactionDataUpdated",
            payload: {
              transactionDataChanged: new Date().getSeconds(),
            },
          });
          setLoading(false);
        }, 2000); // Delay for 2 seconds
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Wystąpiły błedy podczas zapisu umowy.",
        });
        setLoading(false); // Set loading to false even on error
      });
  };

  if (canBeRendered === true) {
    return (
      <>
        {contextHolder}
        <Alert
          message="Czy akceptujesz warunki umowy?"
          description="Każda zmiana warunków umowy wymaga ponownej akceptacji. Aby kontynuować transakcję, obie strony muszą zaakceptować obecne warunki umowy."
          type="warning"
          action={
            <Space direction="vertical">
              <Button type="primary" onClick={handleAccept} loading={loading}>
                Akceptuję
              </Button>
              <Button danger ghost onClick={handleReject} loading={loading}>
                Odrzucam
              </Button>
            </Space>
          }
        />
      </>
    );
  }
};

export default AcceptAgreementAction;
