const initialState = false;

const ACTIONS = {
    NEW_BANK_CREATED: 'bankAccountsListUpdated'
};

export default function filtersReducer(state = initialState, action) {
    if (action.type === ACTIONS.NEW_BANK_CREATED) {
        return action.payload.bankAccountsListUpdated;
    } else {
        return state;
    }
}
