import React from "react";
import { Statistic, Space, Row, Col } from "antd";
const SocialResponsibilityPage = () => {
  return (
    <Row
      style={{
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Col span={12}>
        <h1 className="headline offer">Odpowiedzialność Społeczna PostPay</h1>
        <Space size={30} direction="vertical">
          <p>
            W PostPay, nasza działalność opiera się nie tylko na dostarczaniu
            innowacyjnych rozwiązań finansowych, ale również na pełnieniu roli
            odpowiedzialnego członka społeczności. Jesteśmy przekonani, że
            odpowiedzialność społeczna to nie tylko wartość dodana, ale także
            kluczowy element naszej misji.
          </p>

          <h2>
            Ochrona Środowiska - Nasza Priorytetowa Dziedzina Działalności
          </h2>

          <p>
            Aktywnie angażujemy się w projekty związane z ochroną środowiska,
            ponieważ jesteśmy przekonani, że dbałość o naszą planetę to zadanie,
            które spoczywa na wszystkich naszych barkach. Jednym z naszych
            znaczących projektów jest inicjatywa "ŚmieciAppka." Ta aplikacja
            jest stworzona, aby wspomagać utrzymanie czystości w naszych
            lokalnych społecznościach.
          </p>

          <h2>ŚmieciAppka - Wspieramy Lokalną Czystość</h2>

          <p>
            "ŚmieciAppka" to nasza odpowiedź na wyzwania związane z utrzymaniem
            czystości w naszych miastach i osiedlach. Aplikacja ta umożliwia
            mieszkańcom monitorowanie i raportowanie problemów związanych z
            odpadami. Dzięki niej można zgłaszać miejsca, gdzie występują
            nielegalne wysypiska śmieci, nieodebrane odpady czy inne kwestie
            związane z utrzymaniem czystości publicznej przestrzeni.
          </p>

          <p>
            Nasz zespół pracuje nad ciągłym rozwojem tej aplikacji, wprowadzając
            nowe funkcje i ulepszenia, które ułatwiają lokalnym społecznościom
            utrzymanie czystości i porządku. Naszą intencją jest, aby
            "ŚmieciAppka" stała się narzędziem wsparcia dla działań
            ekologicznych i była dostępna dla wszystkich, którzy chcą aktywnie
            przyczyniać się do poprawy środowiska w swoim otoczeniu.
          </p>

          <h2>Nasza Misja - Wspólny Wkład w Lepszą Przyszłość</h2>

          <p>
            PostPay wierzy, że każdy ma rolę do odegrania w budowaniu lepszej
            przyszłości. Nasza odpowiedzialność społeczna to wyraz naszego
            zaangażowania w sprawy społeczne i ekologiczne. Kontynuujemy pracę
            nad nowymi projektami i inicjatywami, które promują zrównoważony
            rozwój, ochronę środowiska i wsparcie dla lokalnych społeczności.
          </p>

          <p>
            Jesteśmy dumni, że możemy być częścią rozwiązania i dążyć do
            tworzenia pozytywnego wpływu na świat, w którym żyjemy. Razem możemy
            osiągnąć znaczący postęp w kierunku bardziej zrównoważonej i
            odpowiedzialnej przyszłości.
          </p>
        </Space>
      </Col>
    </Row>
  );
};

export default SocialResponsibilityPage;
