import React from "react";
import { Statistic, Space, Row, Col } from "antd";
const ContactPage = () => {
  return (
    <Row
      style={{
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Col md={12} sm={20}>
        <Space size={30} direction="vertical">
          <h1 className="headline offer">Kontakt i pomoc</h1>

          <p>
            Zachęcamy do kontaktu poprzez e-mail kontakt@postpay.pl,
            telefonicznie pod numerem 530 858 861 lub chat online. Biuro Obsługi
            Klienta czynne jest codziennie od 10 do 16.
          </p>
          <p>
            <h2 className="offer">PostPay</h2>
            ul. Fabryczna 5<br />
            73-200 Choszczno
            <br />
          </p>
          <p>
            NIP: 594-153-38-20
            <br />
            REGON: 320305252
            <br />
            <br />
            <i>Jesteśmy płatnikiem VAT</i>
          </p>
        </Space>
      </Col>
    </Row>
  );
};

export default ContactPage;
