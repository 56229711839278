import React from "react";
import ProgressBarTransactionMode1 from "./ProgressBarTransactionMode1";
import ProgressBarTransactionMode3 from "./ProgressBarTransactionMode3";
import ProgressBarTransactionMode4 from "./ProgressBarTransactionMode4";
import ProgressBarTransactionMode5 from "./ProgressBarTransactionMode5";
import ProgressBarTransactionMode6 from "./ProgressBarTransactionMode6";
import ProgressBarTransactionMode7 from "./ProgressBarTransactionMode7";
import ProgressBarTransactionMode8 from "./ProgressBarTransactionMode8";
import ProgressBarTransactionMode10 from "./ProgressBarTransactionMode10";
import ProgressBarTransactionMode11 from "./ProgressBarTransactionMode11";
import ProgressBarTransactionMode12 from "./ProgressBarTransactionMode12";

const ProgressBar = (props) => {
  return (
    <>
      <ProgressBarTransactionMode1
        transaction={props.transaction}
      ></ProgressBarTransactionMode1>
      <ProgressBarTransactionMode3
        transaction={props.transaction}
      ></ProgressBarTransactionMode3>
      <ProgressBarTransactionMode4
        transaction={props.transaction}
      ></ProgressBarTransactionMode4>
      <ProgressBarTransactionMode5
        transaction={props.transaction}
      ></ProgressBarTransactionMode5>      
      <ProgressBarTransactionMode6
        transaction={props.transaction}
      ></ProgressBarTransactionMode6>
      <ProgressBarTransactionMode7
        transaction={props.transaction}
      ></ProgressBarTransactionMode7>
      <ProgressBarTransactionMode8
        transaction={props.transaction}
      ></ProgressBarTransactionMode8>      
      <ProgressBarTransactionMode10
        transaction={props.transaction}
      ></ProgressBarTransactionMode10>
      <ProgressBarTransactionMode11
        transaction={props.transaction}
      ></ProgressBarTransactionMode11>
      <ProgressBarTransactionMode12
        transaction={props.transaction}
      ></ProgressBarTransactionMode12>      
    </>
  );
};

export default ProgressBar;
