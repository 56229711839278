export const deliveryTypes = [
    {
        value: 1,
        label: "Orlen Paczka",
    },
    {
        value: 2,
        label: "Paczkomaty Inpost",
    },
    {
        value: 3,
        label: "DPD",
    },
    {
        value: 4,
        label: "FedEx",
    },
    {
        value: 5,
        label: "Kurier InPost",
    },
    {
        value: 6,
        label: "Pocztex",
    },
    {
        value: 7,
        label: "DHL",
    },
    {
        value: 8,
        label: "Poczta Polska",
    },
    {
        value: 9,
        label: "GLS",
    },
    {
        value: 10,
        label: "Paczka w Ruchu",
    },
    {
        value: 11,
        label: "UPS",
    },
    {
        value: 12,
        label: "Odbiór osobisty",
    },
    {
        value: 13,
        label: "E-mail",
    },
];