import { Button, Modal } from "antd";
import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const WelcomePage = () => {
  const [visible, setVisible] = useState(null);

  const questions = [
    {
      key: "1",
      label: "Czy Postpay zabezpiecza transakcje o małej wartości?",
      content: (
        <p>
          Postpay, zapewniając ochronę i bezpieczeństwo, został zoptymalizowany
          przede wszystkim dla transakcji powyżej 50 zł, co oznacza, że jego
          mechanizmy ochronne są najskuteczniejsze w przypadku większych kwot.
        </p>
      ),
    },
    {
      key: "2",
      label:
        "Czy uzyskam fakturę za usługę zabezpieczenia transakcji przez Postpay?",
      content: (
        <p>
          Oczywiście. Jako firma działająca zgodnie z polskim prawem, jesteśmy
          zobowiązani do wystawienia faktury za świadczone usługi zabezpieczenia
          transakcji. Dzięki temu otrzymasz dokument potwierdzający dokonaną
          transakcję, który będzie spełniał wymogi i przepisy polskiego prawa.
        </p>
      ),
    },
    {
      key: "3",
      label:
        "Ile ostatecznie zapłacę jako kupujący w przypadku zabezpieczenia transakcji kupna roweru za 3000 zł, a ile otrzyma sprzedawca na swoje konto?",
      content: (
        <p>
          Wartość przedmiotów w transakcji: 3 000,00 zł
          <br />
          Kwota do wpłaty przez kupującego: 3 000,00 zł
          <br />
          Kwota, którą otrzyma sprzedający: 2 895,45 zł
          <br />
          <br />
          Prowizja Postpay: 104,55 zł
          <br />
          <br />
          W tym przypadku prowizja Postpay wynosi 104,55 zł. To jest opłata za
          usługę Postpay, która jest pobierana od sprzedawcy (wg zawartej
          umowy).
          <br />
          <br />
          Koszty dostawy: 130,00 zł
          <br />
          <br />
          Dodatkowe koszty dostawy wynoszą 130,00 zł. Te koszty ponosi Katarzyna
          Kowalska, która jest sprzedającą stroną w tej transakcji.
          <br />
          <br />
          Podsumowując, sprzedająca Katarzyna Kowalska ponosi koszty dostawy w
          wysokości 130,00 zł oraz prowizję Postpay w wysokości 104,55 zł.
          Całkowita kwota do zapłaty przez kupującego wynosi 3 000,00 zł.
          Natomiast sprzedający otrzyma kwotę 2 895,45 zł za sprzedany rower.
        </p>
      ),
    },
    {
      key: "4",
      label:
        "Wpłaciłem depozyt do Postpay ale sprzedawca przysłał mi towar niezgodny z umową, co wtedy?",
      content: (
        <p>
          Jeśli wpłaciłeś depozyt do Postpay, a sprzedawca dostarczył ci towar
          niezgodny z umową, to masz możliwość rozwiązania takiej sytuacji.
          Pierwszym krokiem jest kontakt z sprzedawcą w celu wyjaśnienia
          sytuacji. Jeśli nie jesteś w stanie osiągnąć satysfakcjonującego
          rozwiązania z sprzedawcą, skontaktuj się z Postpay i zgłoś spór,
          opisując sytuację i przekazując dowody na niezgodność towaru z umową.
          Postpay będzie starał się pomóc w rozwiązaniu sporu, analizując
          dostępne dowody i dążąc do sprawiedliwego rozwiązania. Postpay działa
          jako pośrednik i stara się zapewnić uczciwe rozstrzygnięcie sporu, co
          chroni Twoje interesy w przypadku problemów z transakcją.
        </p>
      ),
    },
    {
      key: "5",
      label: "Jakie sposoby dostawy są wspierane przez Postpay",
      content: (
        <p>
          Postpay wspiera sposoby dostawy, które umożliwiają śledzenie przesyłki
          za pomocą numeru identyfikacyjnego. Oto opis obsługiwanych sposobów
          dostawy: Orlen Paczka, Paczkomaty Inpost, DPD, FedEx, Kurier InPost,
          Pocztex, DHL, Poczta Polska, GLS, Paczka w Ruchu, UPS, Odbiór
          osobisty, E-mail.
        </p>
      ),
    },
    {
      key: "6",
      label: "Jakie rodzaje umów wspiera Postpay",
      content: (
        <p>
          Postpay jest elastyczną platformą, która umożliwia stronom pełną
          dowolność w tworzeniu i definiowaniu swoich umów. Jednak dla wygody
          użytkowników udostępniamy również 17 gotowych wzorów umów, które można
          wykorzystać natychmiastowo. Dzięki temu masz możliwość dostosowania
          umowy do swoich indywidualnych potrzeb lub skorzystania z jednego z
          naszych gotowych szablonów, co zapewnia szybkość i wygodę w zawieraniu
          transakcji. Dostępne wzory umów:{" "}
          {[
            "Umowa o wykonanie rękodzieła",
            "Umowa o  sprzedaż sklepu internetowego",
            "Umowa o usługi aktualizacyjne/poprawkowe serwera",
            "Umowa o dostarczenie tekstów seo",
            "Umowa o wykonanie audytu technicznego strony",
            "Umowa o wykonanie audytu seo",
            "Umowa o wykonanie logo, plakatu i grafiki",
            "Umowa o wykonanie modyfikacji w sklepie/stronie",
            "Umowa o wykonanie robót budowlanych",
            "Umowa o sprzedaż profilu na Instagram",
            "Umowa o wykonanie sklepu internetowego",
            "Umowa o sprzedaż roweru",
            "Umowa o sprzedaż grupy Facebook",
            "Umowa o sprzedaż antyków z poświa. autentyczności",
            "Umowa o sprzedaż samochodu",
            "Umowa o sprzedaż koparki",
            "Umowa prosta",
          ].map((item, index) => (
            <span
              key={index}
              style={{
                margin: "5px",
                display: "inline-block",
                background: "#eee",
                padding: "2px 8px",
                borderRadius: "4px",
              }}
            >
              {item}
            </span>
          ))}
        </p>
      ),
    },
  ];

  return (
    <>
      {" "}
      <Helmet>
        <title>Bezpieczna płatność Blik, przelew oraz inne formy</title>
      </Helmet>
      <div className="hero-background">
        <div className="hero-background-3">
          <img src="/img/svg/2.svg" alt="" />
        </div>

        <div className="hero-background-5">
          Postpay to ochrona Twoich transakcji w Internecie
        </div>
        <div className="hero-background-7">
          <Link to="/account/register">
            <Button type="primary">ROZPOCZNIJ NOWĄ TRANSAKCJĘ</Button>
          </Link>
        </div>

        <div className="hero-background-8">
          Ochrona transakcji 19,90 + 2,5% od wartości przedmiotu transakcji
        </div>

        <div className="hero-background-4">
          <img src="/img/svg/1.svg" alt="" />
        </div>
      </div>
      <div className="intro">
        Pewnie słyszałeś o takim przypadku, że ktoś{" "}
        <span className="intro-color-swap">
          zapłacił za stronę internetową, a wykonawca zapadł się pod ziemię
        </span>{" "}
        albo ktoś inny zamówił rower na Facebook, a{" "}
        <span className="intro-color-swap">
          po wpłacie zaliczki kontakt zamilkł...
        </span>
      </div>
      <div className="ceo">
        <p>
          {" "}
          W odpowiedzi na rosnący problem transakcji oszukańczych, stworzyliśmy
          usługę Postpay – parasol ochronny dla Twoich transakcji w Internecie.
        </p>
        <p>
          <strong>
            Minimalizujemy ryzyko chroniąc Twoje pieniądze przed oszustwami.
            Wybierz Postpay i ciesz się spokojem podczas każdej transakcji
            online.
          </strong>
        </p>
        <div className="photo">
          <img src="/img/svg/3.svg" />
        </div>
        <div className="sign">
          <img src="/img/svg/4.svg" />
        </div>
        <div className="postpay">Postpay CEO</div>
      </div>
      <div className="forWhom">
        <h3>Dla kogo jest Postpay?</h3>
        <div className="forWhom_personas">
          <div className="forWhom_persona_1">
            <img src="/img/svg/5.svg" />
            <p>Dla Krzysztofa właścicela agencji marketingowej</p>
          </div>
          <div className="forWhom_persona_2">
            <img src="/img/svg/6.svg" />
            <p>Dla Julii tworzącej rzeźby na zamówienie</p>
          </div>
          <div className="forWhom_persona_3">
            <img src="/img/svg/7.svg" />
            <p>Dla Adama poszukiwacza okazji</p>
          </div>
        </div>
        <div className="summary">
          Dzięki Postpay Krzysztof może zlecać zadania podwykonawcom i wypłacać
          wynagrodzenie w momencie ukończenia pracy. Jednocześnie wykonawca ma
          pewność, że Krzysztof już zaalokował środki na tę wypłatę. Julia może
          być pewna wypłaty za wykonaną rzeźbę, a Adam może być pewny, że okazja
          na Facebook nie będzie próbą wyłudzenia pieniędzy.
        </div>
        <div>
          <p className="forwhom">
            {" "}
            Każdy w większym lub mniejszym stopniu dokonuje transakcji
            handlowych przez Internet. Jesteśmy po to, żeby te transakcje były
            bezpieczne.
          </p>
          <div className="forwhombg">
            <img src="/img/svg/8.svg" />
          </div>
        </div>
      </div>
      <div className="howItWorks">
        <h3>Jak działa Postpay?</h3>
        <div className="summary">
          <p>
            Postpay to zautomatyzowana platforma do zawierania bezpiecznych
            transakcji internetowych “1 na 1” dla osób prywatnych i firm.
          </p>
          <p>
            W Postpay tworzysz transakcje, w których ustalasz warunki. Na te
            warunki Ty i Twój kontrahent musicie się zgodzić aby transakcja
            przeszła do kolejnego etapu. Następnie warunki muszą zostać
            wypełnione przez strony transakcji.
          </p>
        </div>
        <div className="graph">
          <img src="/img/svg/9.svg" alt="" />
        </div>
      </div>
      <div className="aboutSection">
        <a href="/about"><Button>Więcej o Postpay</Button></a>&nbsp;&nbsp;
        <a href="/features"><Button>Kluczowe funkcje</Button></a>
      </div>
      <div className="howOtherUse">
        <h3>Co w Postpay najbardziej lubią użytkownicy?</h3>
        <div className="summary">
          Wspólnym mianownikiem wszystkich transakcji przez Postpay jest
          minimalizowane ryzyka utraty środków. Dla małych firm taka utrata to
          czasami być albo nie być, a dla Kowalskiego długie miesiące “odkuwana”
          się.
        </div>
        <div className="howOtherUse_personas">
          <div className="howOtherUse_persona_1">
            <p className="howOtherUse_nick">
              <img src="/img/svg/10.svg" alt="" /> Joanna
            </p>
            <p>
              Tworzę umowę, wysyłam do akceptacji i działamy. Szybki i wygodny
              proces.
            </p>
          </div>
          <div className="howOtherUse_persona_2">
            <p className="howOtherUse_nick">
              <img src="/img/svg/11.svg" alt="" /> Marcin
            </p>
            <p>
              Czytelne rozliczenia pomiędzy kontrahentami, to lubię najbardziej.
            </p>
          </div>
        </div>
        <div className="howOtherUse_personas">
          <div className="howOtherUse_persona_3">
            <p className="howOtherUse_nick">
              <img src="/img/svg/12.svg" alt="" /> Nikodem
            </p>
            <p>
              Wizualizacja etapów transakcji z aktualnym stanem i
              powiadomieniami.
            </p>
          </div>
          <div className="howOtherUse_persona_3">
            <p className="howOtherUse_nick">
              <img src="/img/svg/13.svg" alt="" /> Pola
            </p>
            <p>
              Mogę tworzyć wiele transakcji jednocześnie. Zlecam dużo zadań, to
              dla mnie ważne.
            </p>
          </div>
        </div>
      </div>
      <div className="faq">
        <h3>Najczęściej zadawane pytania</h3>
      </div>
      <div className="questions">
        {questions.map((question) => (
          <div key={question.key} style={{ marginBottom: "16px" }}>
            <a
              href=""
              style={{ width: "300px", overflowX: "hidden" }}
              type="link"
              onClick={(e) => {
                e.preventDefault();
                setVisible(question.key);
              }}
            >
              {question.label}
            </a>
            <Modal
              title={question.label}
              visible={visible === question.key}
              onCancel={() => setVisible(null)}
              footer={[
                <Button key="close" onClick={() => setVisible(null)}>
                  Zamknij
                </Button>,
              ]}
            >
              {question.content}
            </Modal>
          </div>
        ))}
      </div>
      <div className="partners">
        <img src="/img/svg/14.svg" />
      </div>
    </>
  );
};

export default WelcomePage;
