import { Button, Modal } from "antd";
import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const AboutPage = () => {
  const [visible, setVisible] = useState(null);

  return (
    <>
      {" "}
      <Helmet>
        <title>Postpay - bezpieczne transakcje online</title>
      </Helmet>
      <div className="hero-background" style={{ marginTop: "-120px" }}>
        <div className="hero-background-5" style={{ paddingTop: "100px" }}>
          Postpay ma jeden cel. Zapewnić bezpieczne transkacje internetowe dla
          firm oraz osób prywatnych.
        </div>
      </div>
      <div className="abouttxt">
        <p>
          Postpay to zaawansowane rozwiązanie, które integruje skomplikowane
          mechanizmy obsługi różnych scenariuszy transakcji oraz dziesiątki
          punktów decyzyjnych w jednej, łatwej do użycia usłudze. Naszym celem
          jest ułatwienie bezpiecznych transakcji internetowych. Dzięki Postpay
          możesz spokojnie i pewnie dokonywać płatności, zapewniając
          jednocześnie najwyższy poziom bezpieczeństwa swoim transakcjom online.
        </p>
        <h3>Postpay API</h3>
        <p>
          Postpay to nie tylko platforma do zabezpieczania transakcji, ale
          również zaawansowane API w chmurze, które umożliwia pełną komunikację
          z różnymi aplikacjami. Nasza platforma jest gotowa do współpracy z
          dowolnym oprogramowaniem, oferując wszechstronne możliwości
          integracji.
        </p>
        <p>
          Niezależnie od tego, czy potrzebujesz dodatkowej warstwy zabezpieczeń
          w swojej aplikacji, czy chcesz udostępnić funkcje płatności online,
          Postpay API jest gotowe do działania.
        </p>
        <p>
          Zapewniamy elastyczność, niezawodność i pełne wsparcie techniczne, aby
          ułatwić integrację i korzystanie z naszych usług. Zachęcamy do
          współpracy i eksplorowania możliwości, jakie oferuje Postpay API.
          Dzięki naszej platformie możesz zaoferować swoim klientom bezpieczne
          transakcje online oraz wygodne metody płatności.
        </p>
        <p>
          Postpay został ufundowany przez Andrzeja Bernata, doświadczonego
          programistę i architekta oprogramowania, który specjalizuje się w tworzeniu
          oprogramowania chmurowego dostarczanego jako usługi.
          Odpowiedzialny również za budowanie kluczowych modeli biznesowych,
          optymalizację kosztów i dostarczanie wartości klientom.
        </p>
        <h3>Kontakt</h3>
        <p>
          Postpay Bezpieczne Transakcje Internetowe
          <br/>
          ul. Wolności 64B
          <br/>
          73-200 Choszczno
          <br/>
          <br/>
          kontakt@Postpay.pl
          <br/>
          +48 530 858 861
        </p>
      </div>
      <div className="ceo">
        <p>
          {" "}
          W odpowiedzi na rosnący problem transakcji oszukańczych, stworzyliśmy
          usługę Postpay – parasol ochronny dla Twoich transakcji w Internecie.
        </p>
        <p>
          <strong>
            Minimalizujemy ryzyko chroniąc Twoje pieniądze przed oszustwami.
            Wybierz Postpay i ciesz się spokojem podczas każdej transakcji
            online.
          </strong>
        </p>
        <div className="photo">
          <img src="/img/svg/3.svg" />
        </div>
        <div className="sign">
          <img src="/img/svg/4.svg" />
        </div>
        <div className="Postpay">Postpay CEO</div>
      </div>
      <div className="partners">
        <img src="/img/svg/14.svg" />
      </div>
    </>
  );
};

export default AboutPage;
