import React, { useState, useEffect } from "react";
import LoginPage from "../Login/LoginPage";
import { Table, Card, Space, Row, Col, Breadcrumb } from "antd";
import { useSelector } from "react-redux";
import { isLogged, getAuthToken } from "../../helpers/account";
import { api } from "../../config/api";
import { BankOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";
import NewBankForm from "./Account/Bank/Forms/NewBankForm";
import EditBankForm from "./Account/Bank/Forms/EditBankForm";

const columns = [
  {
    title: "Nazwa",
    dataIndex: "name",
    key: "name",
    fixed: "left",
    sorter: true,
    render: (name) => (
      <>
        <u style={{ cursor: "pointer" }}>{name}</u>
      </>
    ),
  },
  {
    title: "Numer konta",
    dataIndex: "number",
    key: "number",
  },
  {
    title: false,
    dataIndex: "uuid",
    key: "uuid",
    render: (uuid) => (
      <>
        <EditBankForm bank={{ uuid: uuid }} />
      </>
    ),
  },
];

function BankAccountsPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const bankAccountsListUpdated = useSelector(
    (state) => state.bankAccountsListUpdated
  );

  useEffect(() => {
    const apiUrl = `${api.host}/bank`;

    if (isLogged()) {
      // Fetch bank account data from the API
      fetch(apiUrl, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching bank account data:", error);
          setLoading(false);
        });
    }
  }, [bankAccountsListUpdated]);

  if (isLogged()) {
    return (
      <>
        <Row
          style={{
            minHeight: "100vh",
          }}
        >
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Space size={15} direction="vertical">
              <Breadcrumb
                items={[
                  {
                    href: "/transactions",
                    title: <HomeOutlined />,
                  },
                  {
                    href: "/account",
                    title: (
                      <>
                        <UserOutlined />
                        <span>Moje Dane</span>
                      </>
                    ),
                  },
                  {
                    title: "Konta Bankowe",
                  },
                ]}
              />

              <Card
                title={
                  <>
                    <BankOutlined /> Konta Bankowe
                  </>
                }
              >
                <Space direction="vertical" size={30}>
                  <NewBankForm />

                  <Table
                    pagination={{ pageSize: 5 }}
                    className="shadow"
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    locale={{
                      emptyText: "Brak dodanych kont bankowych",
                    }}
                  />
                </Space>
              </Card>
            </Space>
          </Col>
        </Row>
      </>
    );
  } else {
    return <LoginPage />;
  }
}

export default BankAccountsPage;
